import AWS from "aws-sdk";

import { waitForSingletonResponse } from "..";
import APIClient from "../../api/apiClient";

const getCreds = async ({ apiClient }: { apiClient: APIClient }) => {
  const {
    data: { credentials },
  } = await apiClient.makeGet({
    url: "/posts/upload/getcreds",
  });
  return credentials;
};

/*
 * TODO
 * Clean up all of this
 * Make sure the IAM role has permissions only for a specific folder in S3
 * move 24ha-post-upload-assume lambda into lambda layer
 */

export const getS3 = waitForSingletonResponse(async (apiClient: APIClient) => {
  const credentials = await getCreds({ apiClient });
  const { AccessKeyId, SecretAccessKey, SessionToken } = credentials;

  AWS.config.update({
    accessKeyId: AccessKeyId,
    secretAccessKey: SecretAccessKey,
    sessionToken: SessionToken,
    region: "us-east-1",
  });

  return new AWS.S3();
});
