import {
  Post,
  PostType,
  PostTypeMedia,
  PostTypeText,
  PostTypePlan,
} from "src/@common/post/types";
import { mapPostByType } from "src/@common/post/mapPost";

const estimateTextHeight = (text: string = "", lineHeight: number = 20) =>
  Math.ceil(text.length / 40) * lineHeight;
const getPostHeightHandler = {
  [PostType.Media]: (post: PostTypeMedia, width: number) => {
    const topBars = (!!post.occasion ? 1 : 0) + (post.firstTime ? 1 : 0);
    return (
      width / post.aspectRatio +
      topBars * 40 +
      (estimateTextHeight(post.text) + 10)
    );
  },
  [PostType.Text]: (post: PostTypeText) => 200,
  [PostType.Plan]: (post: PostTypePlan) =>
    estimateTextHeight(post.text, 30) +
    estimateTextHeight(post.thoughtText, 30) +
    60,
};
export const estimatePostHeight = (post: Post, width: number) =>
  mapPostByType<number, [number]>(post, getPostHeightHandler, width);
