import React from "react";

import { PostTypeText } from "src/@common/post/types";

const PostTextCard: React.FunctionComponent<{
  post: PostTypeText;
  width: number;
}> = ({ post }) => {
  return (
    <div>
      <div>TEXT POST</div>
      <div>{post.id}</div>
      <div>{post.text}</div>
    </div>
  );
};

export default PostTextCard;
