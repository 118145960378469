import React from "react";

// import Background from "./Background";
import { CommonBasePage } from "../common";
import Grid from "../../components/Grid";

const Home: React.FunctionComponent = () => {
  return (
    <CommonBasePage>
      {/* <Background /> */}
      <Grid />
    </CommonBasePage>
  );
};

export default Home;
