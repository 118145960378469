import React from "react";
import styled from "styled-components";

import { PostTypePlan } from "src/@common/post/types";
import { useWindowProperties, M } from "../../../../util/component/dimensions";

import { CardInfo } from "../common";

const ContentContainer = styled.div<M>`
  padding: 27px 32px 30px;
  font-size: ${({ mobile }) => (mobile ? 18 : 22)}px;
`;
const SingleBlock = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`;
const Label = styled.span`
  display: inline-block;
  margin-right: 6px;
  color: #88c;
`;

const PostPlanCard: React.FunctionComponent<{
  post: PostTypePlan;
  width: number;
}> = ({ post }) => {
  const { mobile } = useWindowProperties();

  return (
    <>
      <ContentContainer mobile={mobile}>
        <SingleBlock>
          <Label>one day,</Label>
          {post.text}
        </SingleBlock>
        {post.thoughtText && (
          <SingleBlock>
            <Label>thought of this while</Label>
            {post.thoughtText}
          </SingleBlock>
        )}
      </ContentContainer>
      <CardInfo personId={post.personId} time={post.time} />
    </>
  );
};

export default PostPlanCard;
