import React, { useState, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";

import { useWindowProperties } from "../../../../util/component/dimensions";
import { UnderlineText, Dropdown } from "./styles";

export const UnderlineTextDropdown = styled(UnderlineText)`
  margin-right: 10px;
  &:before {
    width: 84%;
    left: 8%;
  }
  padding: 0 5px;
`;

function ordinal(n: number) {
  switch (n) {
    case 1:
    case 21:
      return n + "st";
    case 2:
    case 22:
      return n + "nd";
    case 3:
    case 23:
      return n + "rd";
    default:
      return n + "th";
  }
}

const DateSelector: React.FunctionComponent<{
  year: number;
  month: number;
  date: number;
  setYear: (n: number) => any;
  setMonth: (n: number) => any;
  setDate: (n: number) => any;
}> = ({ year, month, date, setDate, setMonth, setYear }) => {
  const { mobile } = useWindowProperties();

  const currentYear = new Date().getFullYear();
  const Years = [];
  for (let i = currentYear; i >= 2018; i--) {
    Years.push(
      <option key={i} value={i}>
        {i}
      </option>,
    );
  }

  // show only the number of days in the current month
  const [dayCount, setDayCount] = useState<number>(30);
  useEffect(() => {
    const totalDays = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth();
    setDayCount(totalDays);
  }, [year, month]);

  const Days = [];
  for (let i = 1; i <= dayCount; i++) {
    Days.push(
      <option key={i} value={i}>
        {ordinal(i)}
      </option>,
    );
  }

  return (
    <div>
      <UnderlineTextDropdown mobile={mobile}>
        <Dropdown
          value={year}
          onChange={e => setYear(parseInt(e.target.value))}
        >
          {Years}
        </Dropdown>
      </UnderlineTextDropdown>
      <UnderlineTextDropdown mobile={mobile}>
        <Dropdown
          value={month}
          onChange={e => setMonth(parseInt(e.target.value))}
        >
          {moment.monthsShort().map((m, i) => (
            <option key={m} value={`${i}`}>
              {m}
            </option>
          ))}
        </Dropdown>
      </UnderlineTextDropdown>
      <UnderlineTextDropdown mobile={mobile}>
        <Dropdown
          value={date}
          onChange={e => setDate(parseInt(e.target.value))}
        >
          {Days}
        </Dropdown>
      </UnderlineTextDropdown>
    </div>
  );
};

export default DateSelector;
