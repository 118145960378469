import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import Modal, { ModalProps } from "../../../Modal";
import { UploadedFile } from "../../../../util/component/uploadedFileList";
import Button from "../../../style/Button";
import { LoadingOverlay } from "../../../style/Loading";

const ModalBox = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
`;
const VideoVideo = styled.video`
  display: block;
  width: 100%;
  height: calc(100% - 80px);
  outline: none;
`;
const UseThumbnailButton = styled(Button)`
  margin-top: 20px;
`;

export interface MediaEditorModalProps {
  uploadedFile: UploadedFile;
  uploadVideoThumbnail: (
    uploadedFile: UploadedFile,
    blob: Blob,
  ) => Promise<any>;
}
const CreatePostModalContent: React.FunctionComponent<MediaEditorModalProps & {
  setDNAC: (dnac: boolean) => any;
  close: () => any;
}> = ({ uploadedFile, uploadVideoThumbnail, setDNAC, close }) => {
  const [loading, setLoading] = useState<boolean>(false);
  useEffect(() => {
    setDNAC(loading);
  }, [loading, setDNAC]);

  const uploadThumbnail = async (blob: Blob | null) => {
    if (!blob) return;
    if (loading) return;

    setLoading(true);
    await uploadVideoThumbnail(uploadedFile, blob);
    setLoading(false);
    close();
  };

  const videoRef = useRef<HTMLVideoElement>(null);
  const setThumbnail = () => {
    if (!videoRef.current) return;
    const canvas: HTMLCanvasElement = document.createElement("canvas");
    const { videoWidth, videoHeight } = videoRef.current;
    let scale = 1;
    while (videoWidth * videoHeight * scale * scale > 300000) scale *= 0.9;
    canvas.width = videoWidth * scale;
    canvas.height = videoHeight * scale;
    canvas
      .getContext("2d")!
      .drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    canvas.toBlob(uploadThumbnail, "image/jpeg");
  };

  if (!uploadedFile) return null;

  return (
    <>
      <VideoVideo autoPlay loop controls ref={videoRef}>
        <source type={uploadedFile.file.type} src={uploadedFile.localURL} />
      </VideoVideo>
      <UseThumbnailButton onClick={setThumbnail} disabled={loading}>
        use as thumbnail
      </UseThumbnailButton>
      {loading && <LoadingOverlay />}
    </>
  );
};

const CreatePostModal: React.FunctionComponent<ModalProps & {
  mediaEdit?: MediaEditorModalProps;
}> = ({ mediaEdit, ...modalProps }) => {
  const [doNotAllowClose, setDNAC] = useState<boolean>(false);
  const { close } = modalProps;
  return (
    <Modal
      {...modalProps}
      modalId="create-new-post-edit-media"
      desktopDimensions={{ width: 600, height: 600 }}
      doNotAllowClose={doNotAllowClose}
    >
      <ModalBox>
        {mediaEdit && (
          <CreatePostModalContent
            {...mediaEdit}
            setDNAC={setDNAC}
            close={close}
          />
        )}
      </ModalBox>
    </Modal>
  );
};

export default CreatePostModal;
