import {
  APIPostMediaSetHiddenRequestData,
  APIPostMediaSetHiddenResponse,
} from "src/@common/api/24ha-post-media";

import { APIClient } from "..";

export const setPostMediaHidden = async (
  apiClient: APIClient,
  postId: string,
  mediaId: string,
  hidden: boolean,
) => {
  const {
    data: { hidden: resultHidden },
  } = await apiClient.makePatch<
    APIPostMediaSetHiddenRequestData,
    APIPostMediaSetHiddenResponse
  >({
    url: `/post/${postId}/media/${mediaId}`,
    data: { hidden },
  });

  return resultHidden;
};
