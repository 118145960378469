import {
  PostType,
  Post,
  PostTypeMedia,
  PostTypePlan,
} from "src/@common/post/types";
import { mapPostByType } from "src/@common/post/mapPost";
import {
  APIPostsGetPostRequest,
  APIPostsGetPostResponse,
  APIPostsGetPostsRequest,
  APIPostsGetPostsResponse,
  APIPostsDeleteRequest,
  APIPostsDeleteResponse,
} from "src/@common/api/24ha-posts";

import { IS_ADMIN } from "src/util/admin";

import { APIClient } from "..";
import { MEDIA_FOLDER } from "../../config";

const processPostApi = (postApi: Post): Post => {
  const post: Post = mapPostByType<Post>(postApi, {
    [PostType.Media]: (post: PostTypeMedia) => {
      // set the aspect ratio
      post.media.forEach(media => {
        if (!media.aspectRatio) {
          media.aspectRatio = 1;
        }
      });
      // set the post's overall aspect ratio
      post.aspectRatio = post.media[0]?.aspectRatio || 1;

      // adjust the URLs with our config
      post.media.forEach(media => {
        for (let key in media.url) {
          media.url[key] = `${MEDIA_FOLDER}/${media.url[key]}`;
        }
      });

      // TEMP HIDDEN MEDIA
      if (!IS_ADMIN) {
        // @ts-ignore
        post.media = post.media.filter(m => !m.hidden);
      }

      return post;
    },
    [PostType.Plan]: (post: PostTypePlan) => {
      return post;
    },
  });

  return post;
};

export const getPost = async (apiClient: APIClient, postId: string) => {
  const {
    data: { post },
  } = await apiClient.makeGet<APIPostsGetPostRequest, APIPostsGetPostResponse>({
    url: "/posts",
    params: { postId },
  });

  return processPostApi(post);
};

export const getPosts = async (
  apiClient: APIClient,
  paginationStart?: number,
  paginationCount?: number,
) => {
  const {
    data: { start, end, posts: postsApi },
  } = await apiClient.makeGet<
    APIPostsGetPostsRequest,
    APIPostsGetPostsResponse
  >({
    url: "/posts",
    params: {
      start: paginationStart,
      count: paginationCount,
    },
  });

  const posts: Post[] = postsApi
    .map(processPostApi)
    // TEMP: HIDDEN POSTS
    .filter((post: Post) => {
      if (IS_ADMIN) return true;
      if (post.type !== PostType.Media) return true;
      if (post.media.length) return true;
      return false;
    });

  return { start, end, posts };
};

export const deletePost = async (apiClient: APIClient, postId: string) => {
  const {
    data: { deleted },
  } = await apiClient.makeDelete<APIPostsDeleteRequest, APIPostsDeleteResponse>(
    {
      url: "/posts",
      data: { postId },
    },
  );

  return deleted;
};
