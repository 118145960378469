import styled from "styled-components";

export const HOVER_COLOR = "#339";
export const ACTIVE_COLOR = "#77d";

export default styled.span`
  cursor: pointer;
  &:hover {
    color: ${HOVER_COLOR};
  }
  &:active {
    color: ${ACTIVE_COLOR};
  }
`;
