import {
  createContext,
  useState,
  useCallback,
  useEffect,
  useContext,
} from "react";

import APIClient, { RequestConfig } from "./apiClient";
import { getCredentials } from "../util/auth";
import { IS_DEV } from "../util";

export { APIClient };

export enum AUTH_STATUS {
  LOADING,
  AUTHENTICATED,
  NOT_AUTHENTICATED,
}

declare global {
  interface Window {
    apiClient?: APIClient;
  }
}

export const useAPIClient = (): {
  authStatus: AUTH_STATUS;
  apiClient?: APIClient;
  updateApiClient: () => void;
} => {
  const [authStatus, setAuthStatus] = useState<AUTH_STATUS>(
    AUTH_STATUS.NOT_AUTHENTICATED,
  );
  const [apiClient, setApiClient] = useState<APIClient>();

  const updateApiClient = useCallback(() => {
    setAuthStatus(AUTH_STATUS.NOT_AUTHENTICATED);
    setApiClient(undefined);
    const credentials = getCredentials();
    if (credentials === undefined) return;

    setAuthStatus(AUTH_STATUS.LOADING);
    new APIClient(credentials, client => {
      setApiClient(client);
      setAuthStatus(AUTH_STATUS.AUTHENTICATED);
    });
  }, []);

  useEffect(() => {
    if (IS_DEV) window.apiClient = apiClient;
  }, [apiClient]);

  // on mount, immediately try to get the api client if possible
  useEffect(() => updateApiClient(), [updateApiClient]);

  return { authStatus, apiClient, updateApiClient };
};

export const APIClientContext = createContext<APIClient>(new APIClient());

export const useGetAPIClient = () => useContext(APIClientContext);

export const makeRSAPost = async <
  Request extends object,
  Response extends object
>(
  requestObject: RequestConfig<undefined, Request>,
) =>
  await new APIClient().makeRSAPost<Request, Response>({
    ...requestObject,
  });
