import React, {
  RefObject,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";

export interface Dimensions {
  width: number;
  height: number;
}
export interface DimensionsWithAuto {
  width: number | "auto";
  height: number | "auto";
}

export const useHTMLElementDimensions = (
  elementRef: RefObject<HTMLElement>,
): Dimensions | undefined => {
  // the size of the element
  const [dimensions, setDimensions] = useState<Dimensions | undefined>();

  // a simple state for knowing when to check the window's resizing
  const [shouldResize, setShouldResize] = useState<number>(0);

  // we should check the resize, update the element dimensions
  useEffect(() => {
    if (elementRef.current !== null)
      setDimensions({
        width: elementRef.current.offsetWidth,
        height: elementRef.current.offsetHeight,
      });
  }, [elementRef, shouldResize]);

  // attach event handlers to the window to listen
  useEffect(() => {
    const triggerResize = () => setShouldResize(Math.random());
    window.addEventListener("resize", triggerResize);
    return () => window.removeEventListener("resize", triggerResize);
  }, []);

  return dimensions;
};

export interface WindowProperties {
  mobile: boolean;
  mobile1: 0 | 1;
  dimensions: Dimensions;
}
declare global {
  interface Window {
    visualViewport: { width: number; height: number };
  }
}
const calculateWindowProperties = (): WindowProperties => {
  const mobile = window.visualViewport.width < 600;
  return {
    dimensions: {
      width: window.visualViewport.width,
      height: window.visualViewport.height,
    },
    mobile,
    mobile1: mobile ? 1 : 0,
  };
};

const WindowPropertiesContext = createContext<WindowProperties>(
  calculateWindowProperties(),
);

export const WindowPropertiesContextProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  // the size of the element
  const [windowProperties, setWindowProperties] = useState<WindowProperties>(
    calculateWindowProperties(),
  );

  // a simple state for knowing when to check the window's resizing
  const [shouldResize, setShouldResize] = useState<number>(0);

  // we should check the resize
  useEffect(() => {
    setWindowProperties(calculateWindowProperties());
  }, [shouldResize]);

  // attach event handlers to the window to listen
  useEffect(() => {
    const triggerResize = () => setShouldResize(Math.random());
    window.addEventListener("resize", triggerResize);
    return () => window.removeEventListener("resize", triggerResize);
  }, []);

  return (
    <WindowPropertiesContext.Provider value={windowProperties}>
      {children}
    </WindowPropertiesContext.Provider>
  );
};
export const useWindowProperties = () => useContext(WindowPropertiesContext);
export interface M {
  mobile: boolean;
}
export interface M1 {
  mobile1: 0 | 1;
}
export const M_ = <T extends any>(notMobileValue: T, mobileValue: T) => ({
  mobile,
}: M): T => (mobile ? mobileValue : notMobileValue);

export const M1_ = <T extends any>(values: [T, T] | T[]) => ({
  mobile1,
}: M1): T => values[mobile1];
