import moment from "moment";

import { APIClient } from "../..";
import { Occasion, PostType } from "src/@common/post/types";
import { TempMediaType } from "src/@common/post/temp/types";
import {
  APICreatePostAction,
  APIPostsCreatePostSetupRequest,
  APIPostsCreatePostSetupResponse,
  APIPostsCreatePostMediaAddMediaRequest,
  APIPostsCreatePostMediaAddMediaResponse,
  APIPostsCreatePostMediaConfirmMediaRequest,
  APIPostsCreatePostMediaConfirmMediaResponse,
  APIPostsCreatePostMediaSelectThumbRequest,
  APIPostsCreatePostMediaSelectThumbResponse,
  APIPostsCreatePostCreateRequest,
  APIPostsCreatePostCreateResponse,
  APIPostsCreatePostCreateFinalizeRequest,
  APIPostsCreatePostCreateFinalizeResponse,
} from "src/@common/api/24ha-posts";

export const createTempPostId = async (
  apiClient: APIClient,
  type: PostType,
): Promise<string> =>
  (
    await apiClient.makePost<
      APIPostsCreatePostSetupRequest,
      APIPostsCreatePostSetupResponse
    >({
      url: "/posts",
      data: {
        action: APICreatePostAction.SetupTempPost,
        type,
      },
    })
  ).data.tempPostId;

export const createTempMediaId = async (
  apiClient: APIClient,
  tempPostId: string,
  mediaCount: number = 1,
): Promise<string[]> =>
  (
    await apiClient.makePost<
      APIPostsCreatePostMediaAddMediaRequest,
      APIPostsCreatePostMediaAddMediaResponse
    >({
      url: "/posts",
      data: {
        action: APICreatePostAction.MediaAddMedia,
        tempPostId,
        mediaCount,
      },
    })
  ).data.mediaIds;

export const confirmTempMediaUpload = async (
  apiClient: APIClient,
  tempPostId: string,
  medias: { mediaId: string; mediaType: TempMediaType; raw: string }[],
): Promise<true> => {
  const {
    data: { ok },
  } = await apiClient.makePost<
    APIPostsCreatePostMediaConfirmMediaRequest,
    APIPostsCreatePostMediaConfirmMediaResponse
  >({
    url: "/posts",
    data: {
      action: APICreatePostAction.MediaConfirmMedia,
      tempPostId,
      medias,
    },
  });
  if (!ok) throw new Error("Could not finalize media upload");
  return true;
};

export const setTempMediaThumbnail = async (
  apiClient: APIClient,
  tempPostId: string,
  mediaId: string,
  thumbFileName: string,
): Promise<true> => {
  const {
    data: { ok },
  } = await apiClient.makePost<
    APIPostsCreatePostMediaSelectThumbRequest,
    APIPostsCreatePostMediaSelectThumbResponse
  >({
    url: "/posts",
    data: {
      action: APICreatePostAction.MediaSelectThumb,
      tempPostId,
      mediaId,
      thumbFileName,
    },
  });
  if (!ok) throw new Error("Could not set media thumbnail");
  return true;
};

export interface MediaConfig {
  mediaId: string;
}
export const createPostMedia = async (
  apiClient: APIClient,
  tempPostId: string,
  {
    date,
    mediaConfig,
    text = "",
    occasion,
    firstTime,
  }: {
    date:
      | { today: true }
      | { today: false; year: number; month: number; date: number };
    mediaConfig: MediaConfig[];
    text?: string;
    occasion?: [boolean, Occasion | undefined];
    firstTime?: [boolean, string | undefined];
  },
): Promise<true> => {
  let timestamp: number = new Date().getTime();
  if (!date.today) {
    timestamp = moment(
      `${date.year}-${date.month + 1}-${date.date} 12:00:00`,
    ).valueOf();
  }

  const data: APIPostsCreatePostCreateRequest = {
    action: APICreatePostAction.Create,
    tempPostId,
    createData: {
      mediaConfig,
      time: timestamp,
      text,
    },
  };
  if (occasion && occasion[0])
    data.createData = { ...data.createData, occasion: occasion[1] };
  if (firstTime && firstTime[0])
    data.createData = {
      ...data.createData,
      firstTime: true,
      firstTimeText: firstTime[1],
    };

  const {
    data: { ok },
  } = await apiClient.makePost<
    APIPostsCreatePostCreateRequest,
    APIPostsCreatePostCreateResponse
  >({
    url: "/posts",
    data,
  });
  if (!ok) throw new Error("Could not send request to create post");

  return true;
};
export const createPostPlan = async (
  apiClient: APIClient,
  tempPostId: string,
  {
    text,
    thoughtText,
  }: {
    text: string;
    thoughtText?: string;
  },
): Promise<true> => {
  const data: APIPostsCreatePostCreateRequest = {
    action: APICreatePostAction.Create,
    tempPostId,
    createData: {
      text,
      time: new Date().getTime(),
      thoughtText,
    },
  };

  const {
    data: { ok },
  } = await apiClient.makePost<
    APIPostsCreatePostCreateRequest,
    APIPostsCreatePostCreateResponse
  >({
    url: "/posts",
    data,
  });
  if (!ok) throw new Error("Could not send request to create post");

  return true;
};
export const finalizeCreatePost = async (
  apiClient: APIClient,
  tempPostId: string,
): Promise<string | undefined> => {
  const { data } = await apiClient.makePost<
    APIPostsCreatePostCreateFinalizeRequest,
    APIPostsCreatePostCreateFinalizeResponse
  >({
    url: "/posts",
    data: {
      action: APICreatePostAction.CreateFinalize,
      tempPostId,
    },
  });
  if (!data.posted) return;

  return data.postId;
};
