import React from "react";
import styled from "styled-components";

import { Media } from "src/@common/post/types";

export const MediaImageContainer = styled.div<{ url: string }>`
  width: 100%;
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url(${({ url }) => url});
`;

const MediaImageViewer: React.FunctionComponent<{
  media: Media;
  preview: boolean;
}> = ({ media, preview }) => (
  <MediaImageContainer url={preview ? media.url.preview : media.url.full} />
);

export default MediaImageViewer;
