import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import get from "lodash/get";
import NodeRSA from "node-rsa";

import { EncryptionMethod } from "src/@common/api";
import { AESEncrypt, AESDecrypt } from "src/@common/auth/aes";
import { buildSessionSecuredKey } from "src/@common/auth/utils";

import { IS_DEV } from "../util";
import { SessionCredentials } from "../util/auth";
import { generateRSAKey } from "./auth/rsa";

import { getPost, getPosts, deletePost } from "./posts";
import { setPostMediaHidden } from "./posts/media";
import {
  createTempPostId,
  createTempMediaId,
  confirmTempMediaUpload,
  setTempMediaThumbnail,
  createPostMedia,
  createPostPlan,
  finalizeCreatePost,
} from "./posts/temp";

const API_ENDPOINT =
  process.env.NODE_ENV === "development"
    ? "/api"
    : "https://c3u10aopo1.execute-api.us-east-1.amazonaws.com/api";

const addClient = <T extends any[], K>(
  apiClient: APIClient,
  func: (apiClient: APIClient, ...args: T) => K,
) => (...args: T) => func(apiClient, ...args);

export interface RequestConfig<
  // TODO: deprecate params
  Params extends object | undefined = undefined,
  Data extends object | undefined = undefined
> extends AxiosRequestConfig {
  authenticate?: EncryptionMethod;
  params?: Params;
  data?: Data;
}

/* eslint-disable no-console */
const front = "24ha-request --- ";
const printRequest = (
  path: string,
  method: string = "NO_METHOD_SPECIFIED",
  request: any = {},
) => {
  console.debug(
    `%c${front}%c[${method}] %c${path}`,
    "color: blue; font-weight: bold; text-transform: uppercase; width: 300px;",
    "text-transform: uppercase; font-weight: bold;",
    "color: #333; font-style: italic;",
  );
  console.debug(`${front}%c>>REQUEST: `, "font-weight: bold;", request);
};
const printResponse = (
  path: string,
  method: string = "NO_METHOD_SPECIFIED",
  status: number,
  data: any,
) => {
  console.debug(
    `${front}%c[${method}] %c${path}`,
    "text-transform: uppercase; font-weight: bold;",
    "color: #333; font-style: italic;",
  );
  console.debug(
    `${front}%c<<RESPONSE: %c${status}`,
    "font-weight: bold;",
    "font-weight: bold;",
    data,
  );
};
/* eslint-enable no-console */

export default class APIClient {
  person: number = -1;
  credentials: SessionCredentials | undefined;
  onCredentialsValid?: (apiClient: APIClient) => any;

  RSAKey?: NodeRSA;

  constructor(
    credentials?: SessionCredentials,
    onCredentialsValid?: (apiClient: APIClient) => any,
  ) {
    this.onCredentialsValid = onCredentialsValid;
    if (credentials) this.updateCredentials(credentials);
  }

  getRSAKey = (): NodeRSA => {
    if (!this.RSAKey) this.RSAKey = generateRSAKey();
    return this.RSAKey;
  };

  updateCredentials(credentials: SessionCredentials) {
    this.credentials = credentials;
    this.validateCredentials();
  }
  validateCredentials = async () => {
    if (this.credentials === undefined) return;

    try {
      const result = await this.makeGet({ url: "/login/check" });

      const person = get(result, "data.person", -1);
      if (person === -1)
        throw new Error("Invalid person, or couldn't get identity");
      this.person = person;

      if (this.onCredentialsValid) this.onCredentialsValid(this);
    } catch (e) {
      // credentials are invalid for whatever reason
      // nothing to do because auth is getting rewritten
    }
  };

  axiosRequest = async <R extends any = unknown>(
    requestConfig: AxiosRequestConfig,
  ): Promise<AxiosResponse<R>> => {
    try {
      return await axios.request<R>(requestConfig);
    } catch (e) {
      const {
        response: {
          status = 500,
          data: { error = "WEB_UNKNOWN_ERROR" } = {},
        } = {},
      } = e;
      // eslint-disable-next-line no-throw-literal
      throw {
        status,
        message: error,
        e,
      };
    }
  };

  makeRequest = async <
    Response extends object,
    Params extends object | undefined = undefined,
    Data extends object | undefined = undefined
  >(
    requestObject: RequestConfig<Params, Data>,
  ): Promise<AxiosResponse<Response>> => {
    let {
      url,
      authenticate = EncryptionMethod.Legacy,
      headers = {},
      data,
      params = {},
      method = "post",
    } = requestObject;

    if (authenticate === EncryptionMethod.Legacy) {
      // @ts-ignore
      headers["x-24ha-auth-key"] = this.credentials?.verificationKey;
      // @ts-ignore
      headers["x-24ha-auth-token"] = this.credentials?.verificationToken;
    }

    const requestConfig = {
      ...requestObject,
      headers,
      url: API_ENDPOINT + "" + url,
      data: {},
      params,
    };

    if (authenticate === EncryptionMethod.SessionSecured) {
      if (!this.credentials)
        throw new Error(
          "Attempted to make session secured call without valid credentials",
        );

      // build out our session key
      const [key, requestId, timestamp] = buildSessionSecuredKey(
        this.credentials.token,
      );
      // attach headers
      requestConfig.headers["x-24ha-auth-session-id"] = this.credentials.id;
      requestConfig.headers["x-24ha-auth-session-request"] = requestId;

      const fullData = { ...data, ...params };
      if (IS_DEV)
        printRequest(requestConfig.url, requestConfig.method, fullData);

      // encrypt messaege
      const [encrypted] = AESEncrypt({ data: fullData, _: timestamp }, key);
      if (method === "get") requestConfig.params = { _: encrypted };
      else requestConfig.data = { _: encrypted };

      const response = await this.axiosRequest<{ _: string }>(requestConfig);
      const responseData = AESDecrypt<Response>(response.data._, key);
      if (IS_DEV)
        printResponse(
          requestConfig.url,
          requestConfig.method,
          response.status,
          responseData,
        );

      return {
        ...response,
        data: responseData,
      };
    }

    if (authenticate === EncryptionMethod.RSA) {
      const [encrypted, key] = AESEncrypt(data === undefined ? {} : data!);
      requestConfig.data = {
        _: encrypted,
        __: this.getRSAKey().encrypt(key, "base64"),
      };

      const response = await this.axiosRequest<{ _: string }>(requestConfig);

      return {
        ...response,
        data: AESDecrypt<Response>(response.data._, key),
      };
    }

    return await this.axiosRequest<Response>(requestConfig);
  };

  makeRSAPost = async <Request extends object, Response extends object>(
    requestObject: RequestConfig<undefined, Request>,
  ) => {
    return await this.makeRequest<Response, undefined, Request>({
      ...requestObject,
      method: "post",
      authenticate: EncryptionMethod.RSA,
    });
  };

  makeGet = async <Request extends object, Response extends object>(
    requestObject: RequestConfig<Request, undefined>,
  ) => {
    return await this.makeRequest<Response, Request, undefined>({
      ...requestObject,
      method: "get",
      authenticate: EncryptionMethod.SessionSecured,
    });
  };
  make = async <Request extends object, Response extends object>(
    method: "get" | "post" | "delete" | "patch",
    requestObject: RequestConfig<undefined, Request>,
  ) => {
    return await this.makeRequest<Response, undefined, Request>({
      ...requestObject,
      method,
      authenticate: EncryptionMethod.SessionSecured,
    });
  };
  makePost = async <Request extends object, Response extends object>(
    requestObject: RequestConfig<undefined, Request>,
  ) => await this.make<Request, Response>("post", requestObject);
  makeDelete = async <Request extends object, Response extends object>(
    requestObject: RequestConfig<undefined, Request>,
  ) => await this.make<Request, Response>("delete", requestObject);
  makePatch = async <Request extends object, Response extends object>(
    requestObject: RequestConfig<undefined, Request>,
  ) => await this.make<Request, Response>("patch", requestObject);

  getPost = addClient(this, getPost);
  getPosts = addClient(this, getPosts);
  deletePost = addClient(this, deletePost);
  setPostMediaHidden = addClient(this, setPostMediaHidden);
  createTempPostId = addClient(this, createTempPostId);
  createTempMediaId = addClient(this, createTempMediaId);
  confirmTempMediaUpload = addClient(this, confirmTempMediaUpload);
  setTempMediaThumbnail = addClient(this, setTempMediaThumbnail);
  createPostMedia = addClient(this, createPostMedia);
  createPostPlan = addClient(this, createPostPlan);
  finalizeCreatePost = addClient(this, finalizeCreatePost);
}
