import React, { useEffect } from "react";
import styled from "styled-components";

import Link from "../../../style/Link";
import { OCCASIONS, OCCASIONS_FULL } from "src/@common/post/types";
import { useRouteControls } from "../../../../routes";
import { useWindowProperties, M } from "../../../../util/component/dimensions";

import MediaUpload from "./../MediaUpload";
import {
  ModalLoadingOverlay,
  DateSelector,
  UnderlineText,
  Dropdown,
  Input,
  CreateContentContainerWithBar,
  MajorLine,
  FirstLine,
  LabelText,
  EditMajorLine,
  OptionalBlock,
  OptionalLine,
  TextBox,
  BottomBar,
} from "./../common";
import useTempMediaPost from "./useTempMediaPost";

const OptionSwapper = styled.span<M & { swap?: boolean }>`
  position: relative;
  display: ${({ mobile }) => (mobile ? "block" : "inline-block")};
  ${({ mobile }) => mobile && `height: 35px; margin-top:10px;`}
  > * {
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  > :nth-child(${({ swap }) => (swap ? 1 : 2)}) {
    opacity: 0;
    transform: translate(400px, 0);
  }
`;
const Option = styled.div`
  height: 0;
  overflow: visible;
`;
const PadTop = styled.div`
  position: relative;
  top: 7px;
`;

const MediaLine = styled(MajorLine)`
  margin-top: 35px;
  margin-bottom: 20px;
`;
const TextBoxSpace = styled(TextBox)`
  margin-top: 25px;
  margin-bottom: 2px;
`;

const CreateMemory: React.FunctionComponent<{
  resetPostType: () => any;
  updateDNAC: (key: string, DNAC: boolean) => any;
}> = ({ resetPostType, updateDNAC }) => {
  const routeControls = useRouteControls();
  const { mobile } = useWindowProperties();

  const {
    date: [today, selectedDate, setToday, setSelectedDateFunctions],
    upload,
    text: { text, setText },
    occasion: { occasion, setOccasion },
    firstTime: { firstTime, setFirstTime },
    create: {
      canCreate,
      cannotCreateMessage,
      createPost,
      loading: createPostLoading,
    },
    // reset,
  } = useTempMediaPost((postId: string) => {
    routeControls.goTo.post(postId);
  });

  useEffect(() => {
    if (createPostLoading) {
      updateDNAC("create-memory", true);
      return () => updateDNAC("create-memory", false);
    }
  }, [createPostLoading, updateDNAC]);

  return (
    <>
      {createPostLoading && <ModalLoadingOverlay />}
      <CreateContentContainerWithBar mobile={mobile}>
        <FirstLine>
          <LabelText>i'm posting a memory for</LabelText>
          <OptionSwapper swap={!today} mobile={mobile}>
            <Option>
              <PadTop>
                <UnderlineText mobile={mobile}>today</UnderlineText>
              </PadTop>
            </Option>
            <Option>
              <DateSelector {...selectedDate} {...setSelectedDateFunctions} />
            </Option>
          </OptionSwapper>
        </FirstLine>
        <EditMajorLine>
          <Link onClick={() => resetPostType()}>
            (i want a different kind of post)
          </Link>
        </EditMajorLine>
        <EditMajorLine>
          <Link onClick={() => setToday(today => !today)}>
            (it was {today ? "a different day" : "today"})
          </Link>
        </EditMajorLine>
        <OptionalBlock mobile={mobile} collapse={!occasion[0]}>
          <OptionalLine>
            it was&nbsp;
            <UnderlineText mobile={mobile}>
              <Dropdown
                fade={!occasion[1]}
                value={occasion[1] || ""}
                onChange={e => setOccasion([true, e.target.value])}
              >
                <option value="">select one</option>
                {OCCASIONS.map(o => (
                  <option value={o} key={o}>
                    {OCCASIONS_FULL[o].name}
                  </option>
                ))}
              </Dropdown>
            </UnderlineText>
          </OptionalLine>
        </OptionalBlock>
        <EditMajorLine>
          <Link
            onClick={() => setOccasion(occasion => [!occasion[0], occasion[1]])}
          >
            (it was {occasion[0] ? "not " : ""}a special occasion)
          </Link>
        </EditMajorLine>
        <OptionalBlock mobile={mobile} collapse={!firstTime[0]}>
          <OptionalLine>
            it was the first time&nbsp;
            <Input
              mobile={mobile}
              placeholder="we did ... / that we ..."
              onChange={e => setFirstTime([true, e.target.value])}
              value={firstTime ? firstTime[1] : ""}
            />
          </OptionalLine>
        </OptionalBlock>
        <EditMajorLine>
          <Link
            onClick={() =>
              setFirstTime(firstTime => [!firstTime[0], firstTime[1]])
            }
          >
            (it was {firstTime[0] ? "not " : ""}a first time)
          </Link>
        </EditMajorLine>
        <MediaLine>
          <LabelText>here are some pictures/videos</LabelText>
        </MediaLine>
        <MediaUpload {...upload} />
        <TextBoxSpace
          mobile={mobile}
          placeholder="say something (optional)"
          value={text}
          onChange={e => setText(e.target.value)}
        />
      </CreateContentContainerWithBar>
      <BottomBar
        canCreate={canCreate}
        cannotCreateMessage={cannotCreateMessage}
        onCreate={createPost}
      />
    </>
  );
};

export default CreateMemory;
