import React, { useRef } from "react";
import styled from "styled-components";

import { useHTMLElementDimensions } from "../../util/component/dimensions";

import { PostFilters, usePostRetriever } from "./posts";
import { useGrid } from "./grid";
import { useScrollToBottom } from "./scroll";
import GridLayout from "./GridLayout";
import Bottom from "./Bottom";

const GridContainer = styled.div<{ paddingTop?: number }>`
  padding-top: ${({ paddingTop = 20 }) => paddingTop}px;
`;

const Grid: React.FunctionComponent<{
  filters?: PostFilters;
}> = ({ filters }) => {
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridContainerDimensions = useHTMLElementDimensions(gridContainerRef);

  // fetching posts
  const { posts, fetchStatus, fetchMorePosts } = usePostRetriever(filters);

  // laying them out
  const layoutDefinition = useGrid(posts, gridContainerDimensions);

  // scrolling to load more posts
  useScrollToBottom(fetchMorePosts);

  return (
    <GridContainer
      ref={gridContainerRef}
      paddingTop={layoutDefinition && layoutDefinition.gridSizing.padding * 1.3}
    >
      <GridLayout layoutDefinition={layoutDefinition} />
      <Bottom fetchStatus={fetchStatus} />
    </GridContainer>
  );
};

export default Grid;
