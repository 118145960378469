export interface SessionCredentials {
  id: string;
  token: string;
}

export const getSessionID = () => {
  return window.localStorage.getItem("sessionID") || undefined;
};
export const saveSessionID = (sessionID: string) => {
  window.localStorage.setItem("sessionID", sessionID);
};

export const getSessionToken = () => {
  return window.localStorage.getItem("sessionToken") || undefined;
};
export const saveSessionToken = (sessionToken: string) => {
  window.localStorage.setItem("sessionToken", sessionToken);
};

export const getCredentials = (): SessionCredentials | undefined => {
  const id = getSessionID();
  const token = getSessionToken();
  return id && token ? { id, token } : undefined;
};
