import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";

import { durationToEnglish } from "../../../../util/time";
import { PersonId } from "src/@common/person/types";
import {
  useWindowProperties,
  M,
  M1,
  M_,
  M1_,
} from "../../../../util/component/dimensions";
import Avatar from "../../../Person/Avatar";

const AVATAR_SIZE = [40, 34];
const AVATAR_SPACE = [15, 10];
const AVATAR_SIZE_SPACE = [55, 44];

const CardInfoContainer = styled.div<M>`
  position: relative;
  z-index: 1;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
  padding: ${M_("18px 20px 15px", "12px 14px 12px")};
  font-size: ${M_(16, 14)}px;
`;
const CardInfoAvatarAndText = styled.div`
  > * {
    vertical-align: middle;
    display: inline-block;
  }
`;
const CardText = styled.div<M1>`
  width: calc(100% - ${M1_(AVATAR_SIZE_SPACE)}px);
  margin-left: ${M1_(AVATAR_SPACE)}px;
`;
const CardDate = styled.div`
  font-size: 70%;
  color: #777;
  font-weight: bold;
  text-align: right;
  user-select: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
  &:active {
    color: #aaa;
  }
`;
const CardDateWithText = styled(CardDate)<M1>`
  margin-top: ${M1_([8, 2])}px;
  width: 100%;
`;
const CardDateWithNoText = styled(CardDate)<M1>`
  width: calc(100% - ${M1_(AVATAR_SIZE)}px);
`;

const noop = () => {};
const CardInfo: React.FunctionComponent<{
  action?: () => any;
  personId: PersonId;
  text?: string;
  time: number;
}> = ({ action = noop, personId, text, time }) => {
  const { mobile, mobile1 } = useWindowProperties();

  const [hover, setHover] = useState<boolean>(false);
  const hoverDurationText = moment(time).format("MMMM Do YYYY");
  const elapsedTime = new Date().getTime() - time;
  const durationAgoText = `${durationToEnglish(elapsedTime)} ago`;

  const CardDateTextProps = {
    mobile1,
    onClick: action,
    onMouseOver: () => setHover(true),
    onMouseOut: () => setHover(false),
  };
  const durationText = hover ? hoverDurationText : durationAgoText;

  return (
    <CardInfoContainer mobile={mobile}>
      <CardInfoAvatarAndText>
        <Avatar size={AVATAR_SIZE[mobile1]} personId={personId} />
        {text ? (
          <CardText mobile1={mobile1}>{text}</CardText>
        ) : (
          <CardDateWithNoText {...CardDateTextProps}>
            {durationText}
          </CardDateWithNoText>
        )}
      </CardInfoAvatarAndText>
      {text && (
        <CardDateWithText {...CardDateTextProps}>
          {durationText}
        </CardDateWithText>
      )}
    </CardInfoContainer>
  );
};

export default CardInfo;
