import React from "react";
import styled from "styled-components";

import Loading from "../style/Loading";
import Link from "../style/Link";
import { useRouteControls } from "../../routes";
import { useFiveClickTurnOnAdmin } from "../../util/admin";

import { FetchPostStatus } from "./posts";

const BottomContainer = styled.div`
  padding: 20px 0 50px;
  text-align: center;
`;
const NoMorePosts = styled.div`
  color: #888;
  user-select: none;
  font-size: 100%;
`;
const AddMoment = styled(Link)`
  font-size: 90%;
  margin-top: 12px;
`;

const Bottom: React.FunctionComponent<{ fetchStatus: FetchPostStatus }> = ({
  fetchStatus,
}) => {
  const routeControls = useRouteControls();

  const fiveClick = useFiveClickTurnOnAdmin();

  return (
    <BottomContainer>
      {fetchStatus === FetchPostStatus.LOADING && <Loading />}
      {fetchStatus === FetchPostStatus.IDLE_NO_MORE && (
        <NoMorePosts>
          <div onClick={fiveClick}>~~ no more memories to load ~~</div>
          <AddMoment onClick={() => routeControls.goTo.createNewPost()}>
            want to add a memory?
          </AddMoment>
        </NoMorePosts>
      )}
    </BottomContainer>
  );
};

export default Bottom;
