import { useState, useCallback } from "react";

import { Occasion, PostType } from "src/@common/post/types";

import { useDateSelector, useTempPostId } from "../common";
import useUpload from "../MediaUpload/useUpload";
import useCreatePost from "./useCreatePost";

const useTempMediaPost = (onCreate: (postId: string) => any) => {
  const [occasion, setOccasion] = useState<[boolean, Occasion | undefined]>([
    false,
    undefined,
  ]);
  const [firstTime, setFirstTime] = useState<[boolean, string]>([false, ""]);
  const [text, setText] = useState<string>("");

  const [getTempPostId, resetTempPostId] = useTempPostId(PostType.Media);

  const [date, resetDate] = useDateSelector();

  const [upload, resetUpload] = useUpload(getTempPostId);

  const [create, resetCreatePost] = useCreatePost(
    onCreate,
    getTempPostId,
    {
      date,
      uploadedFiles: upload.uploadedFiles,
      text,
      occasion,
      firstTime,
    },
    {
      isUploading: upload.isUploading,
    },
  );

  const reset = useCallback(() => {
    resetDate();
    resetTempPostId();
    resetUpload();
    resetCreatePost();
    setText("");
    setOccasion([false, undefined]);
    setFirstTime([false, ""]);
  }, [resetCreatePost, resetDate, resetTempPostId, resetUpload]);

  return {
    // date
    date,
    // media
    upload,
    // simpler metadata
    text: { text, setText },
    occasion: { occasion, setOccasion },
    firstTime: { firstTime, setFirstTime },
    // creating the post
    create,
    // resetting the modal
    reset,
  };
};

export default useTempMediaPost;
