import { PersonId } from "../person/types";
import { Media } from "./media";

export enum PostType {
  Media = "media",
  Text = "text",
  Plan = "plan",
}
export const OCCASIONS = [
  "christmas",
  "newyears",
  "birthday1",
  "birthday2",
  "anniversary",
];
export const OCCASIONS_FULL: { [key: string]: { name: string } } = {
  christmas: { name: "christmas" },
  newyears: { name: "new year" },
  birthday1: { name: "roger's birthday" },
  birthday2: { name: "hayoung's birthday" },
  anniversary: { name: "our anniversary" },
};
export type Occasion = typeof OCCASIONS[number];

export interface PostBase {
  // for the db only
  gsi?: number;

  id: string;
  personId: PersonId;
  time: number;
  type: PostType;
}

export interface PostTypeText extends PostBase {
  type: PostType.Text;
  text: string;
}
export interface PostTypeMedia extends PostBase {
  type: PostType.Media;
  media: Media[] & { 0: Media }; // array of length at least 1
  text?: string;
  aspectRatio: number;

  occasion?: Occasion;
  firstTime?: boolean;
  firstTimeText?: string;
}
export interface PostTypePlan extends PostBase {
  type: PostType.Plan;
  text: string;
  thoughtText?: string;
}

export type PostTypeList = [PostType.Media, PostType.Text, PostType.Plan];
export type PostTypeMap = {
  [PostType.Media]: PostTypeMedia;
  [PostType.Text]: PostTypeText;
  [PostType.Plan]: PostTypePlan;
};

export type Post = PostTypeMap[PostType];
