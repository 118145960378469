import React, { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";

import VAlign from "../style/VAlign";
import { XIcon } from "../style/Icons";
import {
  DimensionsWithAuto,
  useWindowProperties,
} from "../../util/component/dimensions";
import {
  useTransitionStyles,
  TransitionState,
} from "../../util/component/transition";

import { useModal, useOpenCloseModal } from "./useOpenCloseModal";

const TRANS_TIME = 0.45;
const ModalContainer = styled.div<{ zIndex?: number }>`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: ${({ zIndex = 1000 }) => zIndex};
  text-align: center;
  overflow: hidden;
`;
const modalBackgroundStyles = [[{}, { opacity: 0 }, { opacity: 1 }], []];
const Background = styled.div`
  transition: opacity ${TRANS_TIME}s ease;
  background: rgba(0, 0, 0, 0.4);
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
`;
const modalContentStyles = [
  [{}, { opacity: 0, top: "100%" }, { opacity: 1, top: "0%" }],
  [{}, { top: "100%" }, { top: "0%" }],
];
const ModalContentContainer = styled.div<{
  mobile?: boolean;
  desktopDimensions?: DimensionsWithAuto;
}>`
  transition: opacity ${TRANS_TIME}s ease, top ${TRANS_TIME}s ease;
  /*   width 0.5s ease, height 0.5s ease; */
  z-index: 2;
  position: relative;
  background: #fff;
  overflow: hidden;
  text-align: left;
  box-sizing: border-box;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.4);

  ${({ mobile, desktopDimensions }) =>
    mobile
      ? `
        width: 100%;
        height: 100%;
      `
      : `
        border-radius: 12px;
        min-width: 150px;
        min-height: 60px;
        max-width: 80%;
        max-height: 80%;
        ${
          desktopDimensions
            ? `
              width: ${
                desktopDimensions.width === "auto"
                  ? desktopDimensions.width
                  : `${desktopDimensions.width}px`
              };
              height: ${
                desktopDimensions.height === "auto"
                  ? desktopDimensions.height
                  : `${desktopDimensions.height}px`
              };
            `
            : ""
        }
      `}
`;
const XIconContainer = styled.div`
  position: absolute;
  z-index: 20;
  right: 15px;
  top: 15px;
  cursor: pointer;
`;

export interface ModalProps {
  modalId?: string;
  show: boolean;
  close: () => any;
  doNotAllowClose?: boolean;
  backgroundClickClose?: boolean;
  desktopDimensions?: DimensionsWithAuto;
}

const Modal: React.FunctionComponent<ModalProps & {
  children: React.ReactNode;
}> = ({
  modalId,
  show,
  close,
  doNotAllowClose,
  backgroundClickClose,
  desktopDimensions,
  children,
}) => {
  const id = useModal(modalId);

  // closing/showing the modal
  const realClose = () => !doNotAllowClose && close();
  const modalZIndex = useOpenCloseModal(show, true, realClose);

  // transitions
  const { mobile, mobile1 } = useWindowProperties();
  const [
    [modalBackgroundStyle, modalContentStyle],
    modalTransitionState,
  ] = useTransitionStyles(show, TRANS_TIME * 1000, [
    modalBackgroundStyles[mobile1],
    modalContentStyles[mobile1],
  ]);

  // creating the div related to the modal
  const [el] = useState(document.createElement("div"));
  useEffect(() => {
    document.body.appendChild(el);
    el.setAttribute("modal-key", id);
    return () => document.body.removeChild(el) && undefined;
  }, [el, id]);

  // show nothing if the modal is off to save on dom processing
  if (modalTransitionState === TransitionState.Off) return null;

  return ReactDOM.createPortal(
    <ModalContainer zIndex={1000 + modalZIndex}>
      {!mobile && (
        <Background
          style={modalBackgroundStyle}
          onClick={() => backgroundClickClose && realClose()}
        />
      )}
      <VAlign height100>
        <ModalContentContainer
          mobile={mobile}
          desktopDimensions={desktopDimensions}
          style={modalContentStyle}
        >
          <XIconContainer>
            <XIcon size={20} clickable onClick={realClose} />
          </XIconContainer>
          {children}
        </ModalContentContainer>
      </VAlign>
    </ModalContainer>,
    el,
  );
};

export default Modal;
