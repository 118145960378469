import React, { useState, useCallback } from "react";

import { useRouteControls } from "../../../routes";
import CreatePostModal from ".";

const CreatePostModalManager: React.FunctionComponent<{}> = () => {
  const [show, setShow] = useState<boolean>(false);

  const routeControls = useRouteControls({
    onRouteChange: useCallback(({ hash }) => setShow(hash === "#new"), []),
  });

  return <CreatePostModal show={show} close={routeControls.removeHash} />;
};

export default CreatePostModalManager;
