import { useState, useEffect } from "react";
import { useAsyncCallback } from "react-async-hook";

import { useRouteControls } from "../../../../routes";
import { useGetAPIClient } from "../../../../api";
import { PostType } from "src/@common/post/types";

import { useTempPostId } from "../common";

const useCreatePlan = () => {
  const apiClient = useGetAPIClient();
  const routeControls = useRouteControls();

  const [text, setText] = useState<string>("");
  const [thoughtText, setThoughtText] = useState<string>("");

  const [getTempPostId] = useTempPostId(PostType.Plan);

  const canCreate = !!text.length;

  const { loading, error, result: postId, execute } = useAsyncCallback(
    async (): Promise<string> => {
      await apiClient.createPostPlan(await getTempPostId(), {
        text,
        thoughtText: thoughtText || undefined,
      });

      const postId = await apiClient.finalizeCreatePost(await getTempPostId());

      if (!postId)
        throw new Error(
          `Could not finalize post - tempPostId ${await getTempPostId()}`,
        );
      return postId;
    },
  );

  useEffect(() => {
    if (postId) routeControls.goTo.post(postId);
  }, [postId, routeControls]);
  useEffect(() => {
    if (error)
      window.alert(
        `that didn't work for some reason.\n\nplease refresh the page\n\n${error}`,
      );
  }, [error]);

  return {
    text: { text, setText },
    thoughtText: { thoughtText, setThoughtText },
    create: () => canCreate && execute(),
    loading,
    canCreate,
  };
};

export default useCreatePlan;
