import React, {
  useState,
  useContext,
  createContext,
  useCallback,
  useEffect,
} from "react";

const AdminControlsContext = createContext<{
  admin: boolean;
  setAdmin: (on: boolean) => void;
}>({
  admin: false,
  setAdmin: () => {},
});

const settingAdmin = localStorage.getItem("24ha_admin") === "true";

export const AdminControlsContextProvider: React.FC = ({ children }) => {
  const [admin] = useState<boolean>(settingAdmin);
  const setAdmin = useCallback((on: boolean) => {
    localStorage.setItem("24ha_admin", `${on}`);
    window.location.reload();
  }, []);

  return (
    <AdminControlsContext.Provider value={{ admin, setAdmin }}>
      {children}
    </AdminControlsContext.Provider>
  );
};

export const IS_ADMIN = settingAdmin;

export const useIsAdmin = (): boolean => {
  const adminContext = useContext(AdminControlsContext);
  return adminContext.admin;
};

export const useSetAdmin = () => {
  const adminContext = useContext(AdminControlsContext);
  return adminContext.setAdmin;
};

export const useFiveClickTurnOnAdmin = () => {
  const [clickState, setClickState] = useState<{
    firstClickTime: number;
    clicks: number;
  }>();

  const onClick = useCallback(() => {
    setClickState(clickState => {
      if (!clickState)
        return { firstClickTime: new Date().getTime(), clicks: 1 };

      if (new Date().getTime() > clickState.firstClickTime + 1000 * 2)
        return undefined;

      return { ...clickState, clicks: clickState.clicks + 1 };
    });
  }, []);

  const setAdmin = useSetAdmin();

  useEffect(() => {
    if (clickState && clickState.clicks >= 5) {
      if (window.confirm("Turn on admin?")) setAdmin(true);
    }
  }, [clickState, setAdmin]);

  return onClick;
};
