export const durationToEnglish = (ms: number) => {
  const mins = ms / 1000 / 60;
  if (mins <= 1) return "less than 1 min";
  if (mins <= 60) return `${Math.round(mins)} min`;

  const hours = mins / 60;
  if (hours <= 24) return `${Math.round(hours)} hrs`;

  const days = hours / 24;
  if (days <= 7) return `${Math.round(days)} days`;

  const weeks = days / 7;
  if (weeks <= 5) return `${Math.round(weeks)} wks`;

  const months = days / 30;
  return `${Math.round(months)} mths`;
};
