import React, { useState, useEffect } from "react";
import styled from "styled-components";

import VAlign from "src/components/style/VAlign";
import { WriteIcon } from "../style/Icons";

import { useRouteControls } from "../../routes";
import { useWindowProperties } from "../../util/component/dimensions";

import AdminControls from "./AdminControls";

export const HEADER_HEIGHT = {
  // mobile = false
  0: {
    // shrink = false
    0: 140,
    // shrink = true
    1: 60,
  },
  // mobile = true
  1: {
    // shrink = false
    0: 80,
    // shrink = true
    1: 50,
  },
};
const LOGO_FONT_SIZE = {
  0: { 0: 82, 1: 30 },
  1: { 0: 30, 1: 20 },
};
const ICON_SIZE = {
  0: { 0: 60, 1: 30 },
  1: { 0: 35, 1: 25 },
};

const TRANSITION = "0.7s ease";

interface HeaderProps {
  shrink: boolean;
  mobile?: boolean;
}

const HeaderContainer = styled.header<HeaderProps>`
  position: fixed;
  text-align: center;
  left: 0;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  transition: box-shadow ${TRANSITION}, height ${TRANSITION},
    background ${TRANSITION};
  height: ${({ shrink, mobile }) =>
    HEADER_HEIGHT[mobile ? 1 : 0][shrink ? 1 : 0]}px;
  background: ${({ shrink }) => (shrink ? "#fff" : "ignore-me")};
  ${({ shrink }) =>
    shrink
      ? `
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
        box-sizing: border-box;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      `
      : ""}

  z-index: 100;
  user-select: none;
`;
const HeaderLogo = styled.h1<HeaderProps>`
  font-family: "Pacifico";
  font-weight: normal;
  transition: font-size ${TRANSITION};
  white-space: nowrap;
  font-size: ${({ shrink, mobile }) =>
    LOGO_FONT_SIZE[mobile ? 1 : 0][shrink ? 1 : 0]}px;
  line-height: 100%;
  margin: 0;
  padding-bottom: 3px;
  position: relative;
  z-index: 1;
`;
const HeaderLowerBar = styled.div<HeaderProps>`
  transition: opacity ${TRANSITION};
  opacity: ${({ shrink }) => (shrink ? 0 : 1)};
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: calc(50% - ${({ mobile }) => (mobile ? 200 / 2 : 500 / 2)}px);
  width: ${({ mobile }) => (mobile ? 200 : 500)}px;
  height: 1px;
  background: rgba(150, 150, 200, 0.5);
`;

const NAV_PAD_LEFT = { 0: { 0: 50, 1: 18 }, 1: { 0: 20, 1: 13 } };
const NavBar = styled.div<HeaderProps>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: left;
  transition: padding-left ${TRANSITION};
  padding-left: ${({ mobile, shrink }) =>
    NAV_PAD_LEFT[mobile ? 1 : 0][shrink ? 1 : 0]}px;
`;

const Header: React.FunctionComponent<{}> = () => {
  const routeControls = useRouteControls();
  const { mobile } = useWindowProperties();
  const [shrink, setShrink] = useState<boolean>(false);

  useEffect(() => {
    const onScroll = () => {
      setShrink(window.scrollY > 60);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const headers = [];
  // have two headers, one for mobile one for desktop
  for (let isMobile of [true, false]) {
    headers.push(
      mobile === isMobile && (
        <HeaderContainer
          key={`mobile-${isMobile}`}
          shrink={shrink}
          mobile={isMobile}
        >
          <VAlign height100>
            <HeaderLogo shrink={shrink} mobile={isMobile}>
              24 hours apart
            </HeaderLogo>
          </VAlign>
          <NavBar shrink={shrink} mobile={isMobile}>
            <VAlign height100>
              <WriteIcon
                size={ICON_SIZE[isMobile ? 1 : 0][shrink ? 1 : 0]}
                transition={TRANSITION}
                clickable
                onClick={() => routeControls.goTo.createNewPost()}
              />
            </VAlign>
          </NavBar>
          <HeaderLowerBar shrink={shrink} mobile={isMobile} />
          <AdminControls />
        </HeaderContainer>
      ),
    );
  }

  return <>{headers}</>;
};

export default Header;
