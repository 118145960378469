import React from "react";
import styled from "styled-components";

const VAlignDiv = styled.div<{ height100: boolean }>`
  vertical-align: middle;
  ${({ height100 }) => (height100 ? "height: 100%;" : "")}
  white-space: nowrap;
  > * {
    display: inline-block;
    vertical-align: middle;
  }
  &::before {
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%;
    content: ".";
    font-size: 0;
    visibility: hidden;
  }
`;

const VAlign: React.FunctionComponent<{
  height100?: boolean;
}> = ({ children, height100 = false }) => (
  <VAlignDiv height100={height100}>{children}</VAlignDiv>
);

export default VAlign;
