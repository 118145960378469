import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";

import { useIsAdmin } from "src/util/admin";
import { useGetAPIClient } from "src/api";

import { universalVideoPause } from "../../../util/component/video";
import useSwipe from "../../../util/component/useSwipe";
import { Media } from "src/@common/post/types";

import MediaViewer from "../Viewer/MediaViewer";
import Bubbles from "./Bubbles";
import Arrows from "./Arrows";

const SliderContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
`;
const SliderSlidingDiv = styled.div<{
  selected: number;
  leftAdjustment?: number;
  transition: boolean;
}>`
  position: absolute;
  left: ${({ selected, leftAdjustment = 0 }) =>
    `calc(-${selected * 100}% + ${leftAdjustment}px)`};
  top: 0;
  ${({ transition }) => transition && `transition: left 0.2s ease;`}
  width: 100%;
  height: 100%;
  overflow: visible;
  white-space: nowrap;
`;
const MediaContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
  position: relative;
`;
const HiddenToggle = styled.div<{ isHidden: boolean }>`
  position: absolute;
  top: 10px;
  right: 10px;
  background: ${({ isHidden }) => (isHidden ? "#e44" : "#ada")};
  padding: ${({ isHidden }) => (isHidden ? 10 : 3)}px;
  cursor: pointer;
  user-select: none;
`;
const BubbleOverlay = styled.div`
  width: 100%;
  position: absolute;
  bottom: 7px;
  text-align: center;
  pointer-events: none;
  > * {
    display: inline-block;
  }
`;

const Slider: React.FunctionComponent<{
  postId: string;
  mediaList: Media[];
  fullSize?: boolean;
}> = ({ postId, mediaList, fullSize = false }) => {
  const isAdmin = useIsAdmin();
  const apiClient = useGetAPIClient();
  const [rr, rerender] = useState<number>(0);

  const [selected, setSelected] = useState<number>(0);

  const moveSelected = (adjust: number) => {
    universalVideoPause();
    setSelected(selected => selected + adjust);
  };

  // make sure the selected value stays within bounds
  useEffect(() => {
    if (selected < 0) setSelected(0);
    if (selected >= mediaList.length) setSelected(mediaList.length - 1);
  }, [selected, mediaList.length]);

  const containerRef = useRef<HTMLDivElement>(null);
  const { startTouch, swipe } = useSwipe((direction: 1 | -1) => {
    moveSelected(direction);
  });

  // swipe adjustment at the start and end of the slider to not move as far as the full swipe
  let leftAdjustment = swipe && swipe.deltaX;
  if (leftAdjustment !== undefined) {
    if (selected === 0 && leftAdjustment > 0)
      leftAdjustment = Math.pow(Math.abs(leftAdjustment), 0.75);
    if (selected === mediaList.length - 1 && leftAdjustment < 0)
      leftAdjustment = -Math.pow(Math.abs(leftAdjustment), 0.75);
  }

  return (
    <SliderContainer ref={containerRef}>
      <SliderSlidingDiv
        selected={selected}
        leftAdjustment={mediaList.length > 1 ? leftAdjustment : undefined}
        transition={mediaList.length !== 1 && !swipe}
      >
        {mediaList.map((media, index) => (
          <MediaContainer key={index} onTouchStart={startTouch}>
            <MediaViewer media={media} preview={!fullSize} />
            {isAdmin && rr === 0 && (
              <HiddenToggle
                isHidden={!!media.hidden}
                onClick={async () => {
                  if (rr > 0) return false;
                  rerender(Math.random());
                  const hidden = await apiClient.setPostMediaHidden(
                    postId,
                    `${index}`,
                    !media.hidden,
                  );
                  media.hidden = hidden;
                  rerender(0);
                }}
              >
                {media.hidden ? "hidden" : "show"}
              </HiddenToggle>
            )}
          </MediaContainer>
        ))}
      </SliderSlidingDiv>
      {mediaList.length > 1 && (
        <>
          <BubbleOverlay>
            <Bubbles total={mediaList.length} selected={selected} />
          </BubbleOverlay>
          <Arrows
            prev={selected > 0 && (() => moveSelected(-1))}
            next={selected < mediaList.length - 1 && (() => moveSelected(1))}
          />
        </>
      )}
    </SliderContainer>
  );
};

export default Slider;
