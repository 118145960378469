import React from "react";
import styled from "styled-components";

import { useWaitForSingletonResponse } from "../../../../util";
import { useGetAPIClient } from "../../../../api";
import { PostType } from "src/@common/post/types";
import VAlign from "../../../style/VAlign";
import Button from "../../../style/Button";
import { LoadingOverlay } from "../../../style/Loading";
import { M } from "../../../../util/component/dimensions";

export { default as DateSelector } from "./DateSelector";
export { default as useDateSelector } from "./useDateSelector";
export * from "./styles";

export const useTempPostId = (type: PostType) => {
  const apiClient = useGetAPIClient();
  return useWaitForSingletonResponse(
    async () => await apiClient.createTempPostId(type),
  );
};

const ModalLoadingOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 23;
`;
export const ModalLoadingOverlay = () => (
  <ModalLoadingOverlayContainer>
    <LoadingOverlay />
  </ModalLoadingOverlayContainer>
);

export const CreateContentContainer = styled.div<M>`
  position: relative;
  z-index: 1;
  ${({ mobile }) =>
    mobile
      ? `
        padding: 25px 25px 40px;
        font-size: 15px;
      `
      : `
        padding: 40px 45px 50px;
        font-size: 20px;
      `}
  line-height: 100%;
  box-sizing: border-box;
  overflow: auto;
  overflow-x: hidden;
  max-height: 100%;
`;
export const CreateContentContainerWithBar = styled(CreateContentContainer)`
  height: calc(100% - 70px);
`;
const ModalBottomBar = styled.div`
  position: relative;
  z-index: 2;
  height: 70px;
  box-shadow: 0px -2px 2px rgba(0, 0, 0, 0.4);
  text-align: right;
  padding-right: 15px;
`;
const CreatePostMessage = styled.div`
  margin-right: 15px;
  color: #555;
`;
export const BottomBar: React.FunctionComponent<{
  canCreate: boolean;
  cannotCreateMessage?: string;
  onCreate: () => any;
}> = ({ canCreate, cannotCreateMessage = "", onCreate }) => (
  <ModalBottomBar>
    <VAlign height100>
      {!canCreate && (
        <CreatePostMessage>{cannotCreateMessage}</CreatePostMessage>
      )}
      <Button onClick={onCreate} disabled={!canCreate}>
        post
      </Button>
    </VAlign>
  </ModalBottomBar>
);

export const MajorLine = styled.div`
  font-size: 140%;
  user-select: none;
  color: #444;
`;
export const EditMajorLine = styled.div`
  font-size: 85%;
  user-select: none;
  color: #aaa;
  margin-bottom: 9px;
`;
export const LabelText = styled.span`
  margin-right: 12px;
`;

export const TextBox = styled.textarea<M>`
  width: 600px;
  max-width: 100%;
  min-height: ${({ mobile }) => (mobile ? 80 : 120)}px;
  box-sizing: border-box;
  padding: ${({ mobile }) => (mobile ? "10px 12px" : "12px 15px")};
  font-family: inherit;
  font-size: 100%;
  line-height: 150%;
  outline: none;
  border: 1px solid #000;
  &::placeholder {
    color: #bbb;
  }
`;

export const FirstLine = styled(MajorLine)`
  margin-bottom: 10px;
`;
export const OptionalBlock = styled.div<M & { collapse?: boolean }>`
  transition: max-height 0.3s ease, padding-top 0.3s ease, opacity 0.3s ease;
  padding-top: ${({ collapse }) => (collapse ? 0 : 22)}px;
  max-height: ${({ collapse, mobile }) => (collapse ? 0 : mobile ? 80 : 50)}px;
  opacity: ${({ collapse }) => (collapse ? 0 : 1)};
  overflow-y: hidden;
`;
export const OptionalLine = styled(MajorLine)`
  padding-bottom: 14px;
  white-space: normal;
  line-height: 150%;
`;
