import { useState, useEffect, useCallback } from "react";
import moment from "moment";

const m = moment();

export default (): [
  [
    boolean,
    { year: number; month: number; date: number },
    React.Dispatch<React.SetStateAction<boolean>>,
    {
      setYear: React.Dispatch<React.SetStateAction<number>>;
      setMonth: React.Dispatch<React.SetStateAction<number>>;
      setDate: React.Dispatch<React.SetStateAction<number>>;
    },
  ],
  () => void,
] => {
  const [today, setToday] = useState<boolean>(true);

  const [year, setYear] = useState<number>(m.year());
  const [month, setMonth] = useState<number>(m.month());
  const [date, setDate] = useState<number>(m.date());

  // if we update the date and its not valid (date > number of days in month) fix it
  useEffect(() => {
    const totalDays = moment(`${year}-${month + 1}`, "YYYY-MM").daysInMonth();
    if (date > totalDays) setDate(totalDays);
  }, [year, month, date]);

  const reset = useCallback(() => {
    setToday(true);
    let m = moment();
    setYear(m.year());
    setMonth(m.month());
    setDate(m.date());
  }, []);

  return [
    [today, { year, month, date }, setToday, { setYear, setMonth, setDate }],
    reset,
  ];
};
