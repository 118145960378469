import React, { useEffect, useCallback, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";

import Home from "../pages/Home";
import SinglePost from "../pages/SinglePost";
import Error404 from "../pages/404";

import { POST_ID_PARAM } from "./params";

const routes: {
  path: string;
  exact?: boolean;
  RouteComponent: React.FunctionComponent;
  noHeader?: boolean;
}[] = [
  {
    path: "/",
    exact: true,
    RouteComponent: Home,
  },
  {
    path: `/post/:${POST_ID_PARAM}`,
    exact: true,
    RouteComponent: SinglePost,
  },
  {
    // catch all route
    path: "*",
    RouteComponent: Error404,
  },
];

export default routes;

export const useRouteControls = ({
  onRouteChange,
}: {
  onRouteChange?: (...args: any) => any;
} = {}) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (onRouteChange) onRouteChange(location);
  }, [location, onRouteChange]);

  const setHash = useCallback(
    (hash: string) =>
      history.push(location.pathname + (hash ? `#${hash}` : "")),
    [history, location.pathname],
  );

  const goTo = useCallback(
    (route: string) => {
      history.push(route);
      window.scrollTo(0, 0);
    },
    [history],
  );

  return useMemo(
    () => ({
      setHash,
      removeHash: () => setHash(""),

      goTo: {
        createNewPost: () => setHash("new"),
        home: () => goTo("/"),
        post: (postId: string) => goTo(`/post/${postId}`),
      },
    }),
    [goTo, setHash],
  );
};
