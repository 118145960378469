export const MEDIA_FOLDER =
  process.env.NODE_ENV === "development"
    ? "https://24hoursapart.com/post-media"
    : "/post-media";

export const TEMP_MEDIA_FOLDER = "posts/temp";
export const buildTempMediaPath = (
  tempPostId: string,
  name: string,
  processed: boolean = false,
) =>
  `${TEMP_MEDIA_FOLDER}/${tempPostId}/${processed ? "processed/" : ""}${name}`;
