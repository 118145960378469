import React from "react";
import styled from "styled-components";

import { useWindowProperties } from "../util/component/dimensions";
import { HEADER_HEIGHT } from "../components/Header";

const Page = styled.div`
  width: 100%;
  box-sizing: border-box;
  text-align: center;
`;

const ContentPaddedContainer = styled.div<{ mobile?: boolean }>`
  padding-top: ${({ mobile }) => HEADER_HEIGHT[mobile ? 1 : 0][0]}px;
  width: 100%;
  max-width: 1400px;
  box-sizing: border-box;
  padding-left: 25px;
  padding-right: 25px;
  display: inline-block;
`;

export const CommonBasePage: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { mobile } = useWindowProperties();

  return (
    <Page>
      <ContentPaddedContainer mobile={mobile}>
        {children}
      </ContentPaddedContainer>
    </Page>
  );
};
