import React from "react";

import { Media } from "src/@common/post/types";
import MediaImageViewer from "./MediaImageViewer";
import MediaVideoViewer from "./MediaVideoViewer";

const MediaViewer: React.FunctionComponent<{
  media: Media;
  preview: boolean;
}> = ({ media, preview }) => {
  if (media.type === "video") {
    return <MediaVideoViewer media={media} preview={preview} />;
  }

  return <MediaImageViewer media={media} preview={preview} />;
};

export default MediaViewer;
