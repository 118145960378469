import React from "react";
import styled from "styled-components";

import {
  Post,
  PostTypeMedia,
  PostTypeText,
  PostTypePlan,
} from "src/@common/post/types";
import { mapPostByType } from "src/@common/post/mapPost";

import { BaseCardProps } from "../PostCard/common";
import PostMediaCard from "../PostCard/PostCardTypes/PostMediaCard";
import PostTextCard from "../PostCard/PostCardTypes/PostTextCard";
import PostPlanCard from "../PostCard/PostCardTypes/PostPlanCard";

const CardContainer = styled.div`
  text-align: left;
  background: #fff;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
`;

const postComponentHandler = {
  media: (post: PostTypeMedia, cardProps: BaseCardProps) => (
    <PostMediaCard post={post} {...cardProps} />
  ),
  text: (post: PostTypeText, cardProps: BaseCardProps) => (
    <PostTextCard post={post} {...cardProps} />
  ),
  plan: (post: PostTypePlan, cardProps: BaseCardProps) => (
    <PostPlanCard post={post} {...cardProps} />
  ),
};

const PostCard: React.FunctionComponent<BaseCardProps & { post: Post }> = ({
  post,
  ...cardProps
}) => {
  return (
    <CardContainer>
      {mapPostByType(post, postComponentHandler, cardProps)}
    </CardContainer>
  );
};

export default PostCard;
