export default "" +
  `-----BEGIN PUBLIC KEY-----
MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBdvOxGc6etFWkNeaKpOnIc
YHdRIBieUPgcKc6urdpcd1HyLLuePjyoAl0MYuKqX2ucXiqkQ4bpu/LOqt/Ung7Y
yfVwXoszF5Jk8EIMLGmjlalho9qhH16xDGfOPCuRYFCLNyBp7xp2+60wRzd4K4sU
0gXB5EgT846GqOiO9+Uib5SRHPsGaKpGWbi0qpju+erMlMOT0QFbObhGD9NQVZS8
P1LXIKWSQ8KJMz9uRBNmk+l7jRxXEVnUWm6OL73ChbNZ+IU00fzCgqRtbMSXx72j
a8Ihm891TPiakWkoBBYIgniXgJjs0fJXtxDVwW2yw77sxB4NTmdhFUxTQCQJkWuH
AgMBAAE=
-----END PUBLIC KEY-----`;
