import { useEffect } from "react";

import useTouch from "./useTouch";
import useSwipeFromTouch from "./useSwipeFromTouch";

const useSwipe = (onSwipe: (direction: 1 | -1) => any) => {
  const { touchStatus, abortTouch, startTouch } = useTouch();

  const { swipe, clearSwipe } = useSwipeFromTouch(touchStatus, abortTouch);

  useEffect(() => {
    // we have an active swipe, but no touch
    // so we need to process an event for "finishing" the swipe
    if (!touchStatus.down && swipe) {
      if (swipe.deltaX > 30) onSwipe(-1);
      if (swipe.deltaX < -30) onSwipe(1);
      clearSwipe();
    }
  }, [touchStatus, swipe]); // eslint-disable-line react-hooks/exhaustive-deps

  return { startTouch, swipe };
};

export default useSwipe;
