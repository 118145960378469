import React from "react";
import styled from "styled-components";

import { useRouteControls } from "../../../../routes";
import { PostTypeMedia } from "src/@common/post/types";
import { BaseCardProps } from "../common";

import EmbedMedia from "./EmbedMedia";
import PostMediaTop from "./PostMediaTop";
import { CardInfo } from "../common";

const CardContainer = styled.div`
  overflow: hidden;
`;
const CardMainMedia = styled.div<{ height: number }>`
  position: relative;
  z-index: 0;
  width: 100%;
  height: ${({ height }) => height}px;
  background: #fff;
`;

const PostMediaCard: React.FunctionComponent<BaseCardProps & {
  post: PostTypeMedia;
}> = ({ post, width, fullSize }) => {
  const routeControls = useRouteControls();
  const goTo = () => routeControls.goTo.post(post.id);

  return (
    <CardContainer>
      <PostMediaTop post={post} />
      <CardMainMedia height={width / post.aspectRatio}>
        <EmbedMedia
          postId={post.id}
          mediaList={post.media}
          fullSize={fullSize}
        />
      </CardMainMedia>
      <CardInfo
        action={goTo}
        personId={post.personId}
        text={post.text}
        time={post.time}
      />
    </CardContainer>
  );
};

export default PostMediaCard;
