import sha256 from "crypto-js/sha256";

const ALPHANUMERIC = "abcdefghijklmnopqrstuvwxyz1234567890";
export const generateSalt = (n: number = 8) => {
  let s = "";
  for (let i = 0; i < n; i++) s += ALPHANUMERIC[Math.floor(Math.random() * 36)];
  return s;
};

const NUMERIC = "1234567890";
export const generateNumericSalt = (n: number = 8) => {
  let s = "";
  for (let i = 0; i < n; i++) s += NUMERIC[Math.floor(Math.random() * 10)];
  return s;
};

export const hashWithSalt = (str: string, salt_?: string) => {
  const salt = salt_ || generateSalt();
  return {
    salt,
    p: sha256(str + salt).toString(),
  };
};

export const buildSessionSecuredKey = (
  token: string,
  timestamp_?: number,
  salt_?: string,
) => {
  const timestamp = timestamp_ || new Date().getTime();
  const salt = salt_ || generateSalt(8);
  const key = hashWithSalt(`${token}-${timestamp}`, salt).p;
  return [key, `${timestamp}-${salt}`, `${timestamp}`];
};
