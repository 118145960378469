import { PersonId } from "../../person/types";
import { PostType, Occasion } from "../types";
import { MediaActualObject } from "./media/process";

export interface TempPostBaseCreatedata {
  time: number;
}
export interface TempPostBase {
  type: PostType;
  person: PersonId;
  id: string;
  timeCreated: number;
  ready?: boolean;
  createData?: TempPostBaseCreatedata;
}
export interface TempPostBaseCreatedata {
  time: number;
}

export interface TempPostPlanCreateData extends TempPostBaseCreatedata {
  text: string;
  thoughtText?: string;
}
export interface TempPostPlan extends TempPostBase {
  type: PostType.Plan;
  createData?: TempPostPlanCreateData;
}

export enum TempMediaType {
  Video = "video",
  Image = "image",
}
export interface TempPostMediaCreateDataMediaConfig {
  mediaId: string;
}
export type TempPostMediaCreateData = TempPostBaseCreatedata & {
  text?: string;
  mediaConfig: TempPostMediaCreateDataMediaConfig[];
  occasion?: Occasion;
} & ({} | { firstTime: true; firstTimeText: string });
export type TempPostMediaObject = {
  id: string;
  uploaded?: boolean;
  raw?: string;
  type?: TempMediaType;
  customThumb?: string;
  processed?: boolean;
  actualObject?: MediaActualObject;
};
export interface TempPostMedia extends TempPostBase {
  type: PostType.Media;
  media: TempPostMediaObject[];
  createData?: TempPostMediaCreateData;
}

export type TempPostCreateData =
  | TempPostPlanCreateData
  | TempPostMediaCreateData;
export type TempPost = TempPostPlan | TempPostMedia;
export type TempPostMap = {
  [PostType.Media]: TempPostMedia;
  [PostType.Plan]: TempPostPlan;
  [PostType.Text]: undefined;
};
