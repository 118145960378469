import { useState, useEffect } from "react";

export enum TransitionState {
  Off,
  OffT,
  OnT,
  On,
}

export const useTransition = (on: boolean, ms: number) => {
  const [transitionState, setTransitionState] = useState<TransitionState>(
    on ? TransitionState.On : TransitionState.Off,
  );

  useEffect(() => {
    let tout: number;
    if (on) {
      if (transitionState === TransitionState.Off) {
        setTransitionState(TransitionState.OffT);
      } else if (transitionState === TransitionState.OffT) {
        tout = setTimeout(() => setTransitionState(TransitionState.OnT), 30);
      } else if (transitionState === TransitionState.OnT) {
        tout = setTimeout(() => setTransitionState(TransitionState.On), ms);
      }
    } else {
      if (transitionState === TransitionState.On) {
        setTransitionState(TransitionState.OnT);
      } else if (transitionState === TransitionState.OnT) {
        tout = setTimeout(() => setTransitionState(TransitionState.OffT), 30);
      } else if (transitionState === TransitionState.OffT) {
        tout = setTimeout(() => setTransitionState(TransitionState.Off), ms);
      }
    }
    return () => clearTimeout(tout);
  }, [ms, on, transitionState]);

  return transitionState;
};

export const useTransitionStyles = (
  on: boolean,
  ms: number,
  stylesList: object[][],
): [object[], TransitionState] => {
  const transitionState = useTransition(on, ms);
  const styles = [];
  for (let singleStyle of stylesList) {
    const [styleOff = {}, styleOffT = {}, styleOnT = {}] = singleStyle;
    switch (transitionState) {
      case TransitionState.Off:
        styles.push(styleOff);
        break;
      case TransitionState.OffT:
        styles.push(styleOffT);
        break;
      default:
        styles.push(styleOnT);
    }
  }
  return [styles, transitionState];
};
