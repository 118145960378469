import { useState, useEffect } from "react";

import { Post } from "src/@common/post/types";
import {
  Dimensions,
  useWindowProperties,
} from "../../util/component/dimensions";
import { estimatePostHeight } from "../Post/dimensions";

interface Column {
  posts: {
    // not sure if this needs to be an object rather than just a direct reference to the post
    // but might add detail/complexity to this object later on
    post: Post;
  }[];
}
export interface GridSize {
  padding: number;
  columnWidth: number;
}

export interface LayoutDefinition {
  gridSizing: GridSize;
  columns: Column[];
}

const DESKTOP_GRID_PADDING = 40;
const MOBILE_GRID_PADDING = 25;

const getGridSizing = (
  width: number,
  columns: number,
  mobile?: boolean,
): GridSize => {
  const GRID_PADDING = mobile ? MOBILE_GRID_PADDING : DESKTOP_GRID_PADDING;
  const columnWidth = (width - (columns - 1) * GRID_PADDING) / columns;
  return {
    padding: GRID_PADDING,
    columnWidth,
  };
};
const getColumnsFromWidth = (width: number): number => {
  if (width < 600) return 1;
  if (width < 900) return 2;
  return 3;
};

export const useGrid = (
  posts: Post[],
  dimensions?: Dimensions,
): LayoutDefinition | undefined => {
  const [layout, setLayout] = useState<LayoutDefinition>();
  const { mobile } = useWindowProperties();

  // 1. when the list of posts change 2. onResize / width changes
  useEffect(() => {
    if (dimensions === undefined || posts.length === 0) {
      setLayout(undefined);
      return;
    }

    const columnCount = getColumnsFromWidth(dimensions.width);
    const gridSizing = getGridSizing(dimensions.width, columnCount, mobile);

    // updating the columns
    const newColumns: Column[] = [];
    for (let i = 0; i < columnCount; i++)
      newColumns.push({
        posts: [],
      });
    const colHeights = newColumns.map(() => 0);

    posts.forEach(post => {
      // the column we're going to append to
      const lowestColIndex = colHeights.indexOf(Math.min(...colHeights));

      // estimated height of that post
      const postHeight = estimatePostHeight(post, gridSizing.columnWidth);
      // append it to the column
      colHeights[lowestColIndex] += postHeight + gridSizing.padding;

      newColumns[lowestColIndex].posts.push({ post });
    });

    setLayout({ gridSizing, columns: newColumns });
  }, [mobile, posts, dimensions]);

  return layout;
};
