import { useState, useEffect, useCallback } from "react";

import { Post } from "src/@common/post/types";

import { useGetAPIClient } from "src/api";

const DEFAULT_PAGE_SIZE = 10;

export interface PostFilters {
  [key: string]: string;
}
const noFilters = {};

export enum FetchPostStatus {
  IDLE,
  IDLE_NO_MORE,
  LOAD_NEXT,
  LOADING,
}

export const usePostRetriever = (
  filters: PostFilters = noFilters,
): {
  posts: Post[];
  fetchMorePosts: () => any;
  fetchStatus: FetchPostStatus;
} => {
  // current state of making a fetch request
  const [fetchState, setFetchState] = useState<{
    status: FetchPostStatus;
    start?: number;
    count?: number;
  }>({ status: FetchPostStatus.IDLE });
  // all the posts
  const [posts, setPosts] = useState<Post[]>([]);

  const apiClient = useGetAPIClient();

  // whenever the fetching status changes
  useEffect(() => {
    // we have to go load more
    (async () => {
      if (fetchState.status === FetchPostStatus.LOAD_NEXT) {
        // update to know that we're loading
        setFetchState({ status: FetchPostStatus.LOADING });

        // make the request to get more posts
        const { start = 0, count } = fetchState;
        let { posts: newPosts, end: endPosts } = await apiClient.getPosts(
          start,
          count,
        );

        // no more posts
        if (newPosts.length === 0) {
          setFetchState({
            status: FetchPostStatus.IDLE_NO_MORE,
          });
          return;
        }

        // await new Promise(resolve => setTimeout(() => resolve(true), 2000));
        setPosts([...posts, ...newPosts]);

        // we're now done, back to idle, and update the next start
        setFetchState({
          status: FetchPostStatus.IDLE,
          start: endPosts,
        });
      }
    })();
  }, [fetchState]); // eslint-disable-line react-hooks/exhaustive-deps

  const fetchMorePosts = useCallback(
    async (count: number = DEFAULT_PAGE_SIZE) => {
      // go and fetch more posts but only if we're currently idle (not already queued/loading)
      setFetchState(currentState =>
        currentState.status === FetchPostStatus.IDLE
          ? { ...currentState, status: FetchPostStatus.LOAD_NEXT, count }
          : currentState,
      );
    },
    [],
  );

  // whenever the filters change (or on componentDidMount) go fetch a fresh batch of posts
  useEffect(() => {
    fetchMorePosts(DEFAULT_PAGE_SIZE * 2);
  }, [filters]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    posts,
    fetchMorePosts,
    fetchStatus: fetchState.status,
  };
};
