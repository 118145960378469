import React from "react";
import styled from "styled-components";

const BubbleContainer = styled.div`
  opacity: 0.6;
  background: rgba(0, 0, 0, 0.4);
  padding: 5px 4px;
  border-radius: 7px;
  font-size: 0;
`;
const SingleBubble = styled.div<{ active: boolean }>`
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin: 0 3px;
  background: ${({ active }) => (active ? "#99c" : "#fff")};
`;

const Bubbles: React.FunctionComponent<{ selected: number; total: number }> = ({
  selected,
  total,
}) => (
  <BubbleContainer>
    {[...Array(total).keys()].map(i => (
      <SingleBubble key={i} active={i === selected} />
    ))}
  </BubbleContainer>
);

export default Bubbles;
