import styled from "styled-components";
import { M } from "../../../../util/component/dimensions";

export const UnderlineText = styled.span<M>`
  position: relative;
  &:before {
    content: " ";
    font-size: 0;
    width: 100%;
    height: ${({ mobile }) => (mobile ? 2 : 3)}px;
    bottom: ${({ mobile }) => (mobile ? -2 : -5)}px;
    background: #000;
    position: absolute;
    left: 0;
  }
`;

export const Dropdown = styled.select<{ fade?: boolean }>`
  ${({ fade }) => fade && "color: #aaa;"}
  display: inline-block;
  border: none;
  font-size: inherit;
  font-family: inherit;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
`;

export const Input = styled.input<M>`
  font-size: inherit;
  font-family: inherit;
  border: none;
  border-bottom: ${({ mobile }) => (mobile ? 2 : 3)}px solid #000;
  width: ${({ mobile }) => (mobile ? 250 : 300)}px;
  position: relative;
  outline: none;
  padding: 0 0 ${({ mobile }) => (mobile ? 2 : 5)}px;
  &::placeholder {
    color: #aaa;
  }
`;
