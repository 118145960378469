import { useState, useEffect, useCallback } from "react";

export const IS_DEV = process.env.NODE_ENV === "development";

export const wait = async (ms: number) =>
  await new Promise((resolve: () => any) => setTimeout(resolve, ms));

const waitForTrue = async (callMe: () => boolean, interval: number = 200) => {
  while (true) {
    if (callMe() === true) break;
    await new Promise(resolve => setTimeout(resolve, interval));
  }
};
const waitForTrigger = (): [() => void, () => Promise<void>] => {
  let ok = false;
  const trigger = () => {
    ok = true;
  };
  return [trigger, async () => await waitForTrue(() => ok)];
};
export const waitForCallback = async <K>(
  func: (done: (response: K) => void) => any,
): Promise<K> => {
  const [callBack, wait] = waitForTrigger();
  let response: K;
  func((resp: K) => {
    response = resp;
    callBack();
  });
  // wait s3 to finish uploading
  await wait();
  return response!;
};

export const waitForSingletonResponse = <K, T extends any[]>(
  func: (...args: T) => Promise<K>,
) => {
  // 0 = not requested, 1 = loading, 2 = got it
  const status: { status: 0 | 1 | 2; response: K | undefined } = {
    status: 0,
    response: undefined,
  };

  const getSingleton = async (...args: T): Promise<K> => {
    // wait for it to not be 1, so either it's 0 or 2
    await waitForTrue(() => status.status !== 1);
    // if its 0, go fetch it
    if (status.status === 0) {
      status.status = 1;
      status.response = await func(...args);
      status.status = 2;
    }
    await waitForTrue(() => status.status !== 1);
    // either weve fetched it or its already fetched, return it
    return status.response!;
  };

  return getSingleton;
};

export const useWaitForSingletonResponse = <K, T extends any[]>(
  func: (...args: T) => Promise<K>,
): [(...args: T) => Promise<K>, () => void] => {
  // 0 = not requested, 1 = loading, 2 = got it
  const [status, setStatus] = useState<{
    status: 0 | 1 | 2;
    response: K | undefined;
  }>({
    status: 0,
    response: undefined,
  });

  const getSingleton = async (...args: T): Promise<K> => {
    if (status.response !== undefined) return status.response;
    // wait for it to not be 1, so either it's 0 or 2
    await waitForTrue(() => status.status !== 1);
    // if its 0, go fetch it
    if (status.status === 0) {
      status.status = 1;
      status.response = await func(...args);
      status.status = 2;
    }
    await waitForTrue(() => status.status !== 1);
    // either weve fetched it or its already fetched, return it
    return status.response!;
  };

  const reset = useCallback(() => {
    setStatus({ status: 0, response: undefined });
  }, []);

  return [getSingleton, reset];
};

export const useQueuedAction = <K>(
  // return the processed elements, or void if everything was processed
  processQueue: (inputQueue: K[]) => Promise<K | K[] | void>,
) => {
  const [queue, setQueue] = useState<K[]>([]);
  const [add, setAdd] = useState<K[]>([]);
  // 0 idle 1 active
  const [status, setStatus] = useState<0 | 1>(0);

  const addItems = (items: K | K[]) => {
    setAdd(add => {
      if (Array.isArray(items)) return [...add, ...items];
      return [...add, items];
    });
  };

  useEffect(() => {
    if (status === 0 && queue.length) {
      setStatus(1);
      (async () => {
        const inputQueue = queue;
        const result = await processQueue(inputQueue);
        const completedQueue: K[] =
          result === undefined
            ? inputQueue
            : Array.isArray(result)
            ? result
            : [result];

        setQueue(queue =>
          queue.filter(item => completedQueue.indexOf(item) === -1),
        );
        setStatus(0);
      })();
    }
  }, [queue, status]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (status === 0 && add.length) {
      setAdd([]);
      setQueue(queue => [...queue, ...add]);
    }
  }, [add, status]);

  return addItems;
};
export const useAsyncQueuedAction = <K>(
  processQueue: (inputQueue: K[]) => Promise<K | K[] | void>,
) => {
  const addItems = useQueuedAction<{ obj: K; callBack: () => any }>(
    async queueItems => {
      // call the undelrying process queue function
      const result = await processQueue(queueItems.map(item => item.obj));
      const returnItems =
        result === undefined
          ? queueItems.map(item => item.obj)
          : Array.isArray(result)
          ? result
          : [result];

      // map the returned Ks back to the overarching queueItems to return
      const queueReturnItems = queueItems.filter(
        item => returnItems.indexOf(item.obj) > -1,
      );

      // trigger the callbacks
      queueReturnItems.forEach(({ callBack }) => callBack());

      return queueReturnItems;
    },
  );

  return async (items: K | K[]) => {
    const arrayItems = Array.isArray(items) ? items : [items];
    await Promise.all(
      arrayItems.map(async item => {
        // for each item, addItem and wait for the callback
        await waitForCallback<void>(done => {
          addItems({
            obj: item,
            callBack: () => done(),
          });
        });
      }),
    );
  };
};

// this allows you to pass in a function that will change its execution
// based on the scoped values it uses
// but it returns a single callback never changing function that you can trigger
// that will still trigger the function that you want to change behavior over time
export const useUnscopedCallback = <T extends any[]>(
  func: (...args: T) => any,
) => {
  const [trigger, setTrigger] = useState<[number, T] | undefined>();
  useEffect(() => {
    if (trigger === undefined) return;
    setTrigger(undefined);
    func(...trigger[1]);
  }, [func, trigger]);
  return useCallback((...args: T) => setTrigger([Math.random(), args]), []);
};
