import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

// global controls
import AuthEntry from "./Auth/AuthEntry";
import { WindowPropertiesContextProvider } from "./util/component/dimensions";
import { AdminControlsContextProvider } from "./util/admin";
import {
  ModalControlsContextProvider,
  modalControlsContextValue,
} from "./components/Modal/modalControls";

import routes from "./routes";
import Header from "./components/Header";
import CreatePostModalManager from "./components/Post/CreatePostModal/CreatePostModalManager";

import "./app.css";

const GlobalControls = ({ children }: { children: React.ReactNode }) => (
  <AuthEntry>
    <AdminControlsContextProvider>
      <WindowPropertiesContextProvider>
        <ModalControlsContextProvider value={modalControlsContextValue}>
          {children}
        </ModalControlsContextProvider>
      </WindowPropertiesContextProvider>
    </AdminControlsContextProvider>
  </AuthEntry>
);

const App: React.FunctionComponent<{}> = () => {
  return (
    <GlobalControls>
      <Router>
        <>
          <CreatePostModalManager />
        </>
        <Switch>
          {routes.map(
            ({ path, exact = true, RouteComponent, noHeader = false }) => (
              <Route path={path} exact={exact} key={path}>
                {!noHeader && <Header />}
                <RouteComponent />
              </Route>
            ),
          )}
        </Switch>
      </Router>
    </GlobalControls>
  );
};

export default App;
