import AES from "crypto-js/aes";
import Utf8 from "crypto-js/enc-utf8";

import { generateSalt } from "../utils";

export const AESEncrypt = <M extends object = {}>(
  message: M,
  key?: string,
): [string, string] => {
  const k: string = key || generateSalt(16);
  const encrypted = AES.encrypt(JSON.stringify(message), k).toString();
  return [encrypted, k];
};

export const AESDecrypt = <M extends object = {}>(
  encrypted: string,
  key: string,
): M => {
  const bytes = AES.decrypt(encrypted, key);
  return JSON.parse(bytes.toString(Utf8));
};
