import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { getSessionID, getSessionToken } from "../util/auth";

import Step1 from "./Step1";
import Step2 from "./Step2";

const AuthPage = styled.div`
  text-align: center;
`;
const Header = styled.h1`
  font-size: 30px;
  font-family: Pacifico;
  font-weight: normal;
`;

const Login = ({ refreshCredentials }: { refreshCredentials: () => any }) => {
  const [sessionID, setSessionID] = useState<string>();
  const [sessionToken, setSessionToken] = useState<string>();

  useEffect(() => {
    if (sessionID && sessionToken) refreshCredentials();
  }, [refreshCredentials, sessionID, sessionToken]);

  return (
    <AuthPage>
      <Header>24 hours apart</Header>
      {sessionID === undefined ? (
        <Step1
          onComplete={() => {
            setSessionID(getSessionID());
          }}
        />
      ) : (
        <Step2
          sessionID={sessionID}
          onComplete={() => {
            setSessionToken(getSessionToken());
          }}
        />
      )}
    </AuthPage>
  );
};

export default Login;
