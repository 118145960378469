import React from "react";
import { useAsync } from "react-async-hook";
import styled from "styled-components";

import { CommonBasePage } from "../common";
import { useRouteControls } from "../../routes";
import { usePostId } from "../../routes/params";
import { useWindowProperties } from "../../util/component/dimensions";
import { useGetAPIClient } from "../../api";
import Loading from "../../components/style/Loading";
import { ArrowLeftIcon } from "../../components/style/Icons";
import FullPost from "../../components/Post/FullPost";

const PostContainer = styled.div<{ width: number }>`
  display: inline-block;
  padding-top: 30px;
  padding-bottom: 150px;
  width: ${({ width }) => width}px;
`;

const GoBackArrow = styled.div`
  margin-bottom: 30px;
`;
const ArrowLeftCircle = styled.div`
  display: inline-block;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  padding: 14px;
  background: #fff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.2);
  > * {
    opacity: 0.8;
  }
  cursor: pointer;
  transition: background 0.1s ease;
  &:hover {
    background: #eee;
  }
  &:active {
    background: #aaa;
  }
`;

const SinglePost: React.FunctionComponent = () => {
  const routeControls = useRouteControls();
  const {
    mobile,
    dimensions: { width },
  } = useWindowProperties();

  const postId = usePostId();
  const apiClient = useGetAPIClient();

  const { loading, error, result: post } = useAsync(
    async () => await apiClient.getPost(postId),
    [postId],
  );

  const postWidth = mobile ? width - 50 : Math.min(width * 0.8, 700);

  return (
    <CommonBasePage>
      <PostContainer width={postWidth}>
        <GoBackArrow>
          <ArrowLeftCircle onClick={routeControls.goTo.home}>
            <ArrowLeftIcon size={30} />
          </ArrowLeftCircle>
        </GoBackArrow>
        {loading ? (
          <Loading />
        ) : post ? (
          <FullPost post={post} width={postWidth} fullSize />
        ) : error ? (
          <div>hmm, we couldn't find that post</div>
        ) : (
          <div>??</div>
        )}
      </PostContainer>
    </CommonBasePage>
  );
};

export default SinglePost;
