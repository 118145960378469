import styled from "styled-components";

export const Label = styled.div`
  margin-bottom: 10px;
`;
export const ErrorMessage = styled.div`
  margin-top: 10px;
  color: red;
`;
export const LoadingMessage = styled.div`
  margin-top: 10px;
  color: #666;
`;
