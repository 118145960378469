import React, { useState, useEffect } from "react";
import styled from "styled-components";

import BackgroundImage from "../../../style/BackgroundImage";
import VAlign from "../../../style/VAlign";
import { PlusIcon, PlayIcon } from "../../../style/Icons";
import { UploadedFile } from "../../../../util/component/uploadedFileList";
import { M, useWindowProperties } from "../../../../util/component/dimensions";

const UploadedFileContainer = styled.div<M & { clickable?: boolean }>`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: ${({ mobile }) => (mobile ? 80 : 100)}px;
  height: ${({ mobile }) => (mobile ? 80 : 100)}px;
  margin-right: ${({ mobile }) => (mobile ? 12 : 20)}px;
  margin-bottom: ${({ mobile }) => (mobile ? 12 : 20)}px;
  box-sizing: border-box;
  border: 2px solid #116;
  ${({ clickable }) =>
    clickable
      ? `
        cursor: pointer;
        &:hover {
          opacity: 0.8;
        }
        &:active {
          opacity: 0.6;
        }
      `
      : ``};
`;
const UploadedFileContainerAdd = styled(UploadedFileContainer)`
  border: 4px solid #116;
  opacity: 0.6;
  cursor: pointer;
  text-align: center;
  &:hover {
    opacity: 0.4;
  }
  &:active {
    opacity: 0.9;
  }
`;
const PlayIconContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  text-align: center;
`;
const PlayIconC = styled.div`
  display: inline-block;
  padding: 6px;
  border-radius: 7px;
  background: rgba(255, 255, 255, 0.5);
`;
const UploadedFileImage = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
`;
const UploadedFileVideo = styled.video`
  width: 100%;
  height: 100%;
`;
const Overlay = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  text-align: center;
`;
const ProgressBarContainer = styled.div`
  width: 55%;
  height: 8px;
  border: 1px solid #000;
  background: rgba(255, 255, 255, 0.7);
`;
const ProgressBar = styled.div<{ progress: number }>`
  background: rgba(0, 0, 240, 0.8);
  width: ${({ progress }) => progress * 100}%;
  height: 100%;
`;

export const UploadedFileAdd: React.FunctionComponent<{
  onClick: () => any;
}> = ({ onClick }) => {
  const { mobile } = useWindowProperties();

  return (
    <UploadedFileContainerAdd onClick={onClick} mobile={mobile}>
      <VAlign height100>
        <PlusIcon size={40} />
      </VAlign>
    </UploadedFileContainerAdd>
  );
};

const SingleUploadedFile: React.FunctionComponent<{
  uploadedFile: UploadedFile;
  clickable: boolean;
  onClick?: () => any;
}> = ({ uploadedFile, clickable, onClick = () => {} }) => {
  const { mobile } = useWindowProperties();

  const [thumbURL, setThumbURL] = useState<string>();
  useEffect(() => {
    if (uploadedFile.thumb) {
      setThumbURL(URL.createObjectURL(uploadedFile.thumb.blob));
    } else {
      setThumbURL(undefined);
    }
  }, [uploadedFile.thumb]);

  return (
    <UploadedFileContainer
      mobile={mobile}
      clickable={clickable}
      onClick={onClick}
    >
      {thumbURL ? (
        <>
          <UploadedFileImage url={thumbURL} />
          <PlayIconContainer>
            <VAlign height100>
              <PlayIconC>
                <PlayIcon size={30} />
              </PlayIconC>
            </VAlign>
          </PlayIconContainer>
        </>
      ) : uploadedFile.fileType === "image" ? (
        <UploadedFileImage url={uploadedFile.localURL} />
      ) : (
        <UploadedFileVideo autoPlay loop muted controls={false}>
          <source type={uploadedFile.file.type} src={uploadedFile.localURL} />
        </UploadedFileVideo>
      )}
      {!uploadedFile.uploadConfirmed && (
        <Overlay>
          <VAlign height100>
            <ProgressBarContainer>
              <ProgressBar progress={uploadedFile.uploadProgress} />
            </ProgressBarContainer>
          </VAlign>
        </Overlay>
      )}
    </UploadedFileContainer>
  );
};

export default SingleUploadedFile;
