import React from "react";
import styled from "styled-components";

import { Media } from "src/@common/post/types";
import Slider from "../../../Media/Slider";

const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;

const EmbedMedia: React.FunctionComponent<{
  postId: string;
  mediaList: Media[];
  fullSize?: boolean;
}> = ({ postId, mediaList, fullSize }) => {
  return (
    <MediaContainer>
      <Slider postId={postId} mediaList={mediaList} fullSize={fullSize} />
    </MediaContainer>
  );
};

export default EmbedMedia;
