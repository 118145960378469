import React from "react";
import styled from "styled-components";

import loadingIcon from "./loading.svg";
import VAlign from "../VAlign";

const Loading = () => (
  <div>
    <img src={loadingIcon} alt="loading" />
  </div>
);
export default Loading;

const LoadingOverlayContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  text-align: center;
`;
export const LoadingOverlay = () => (
  <LoadingOverlayContainer>
    <VAlign height100>
      <Loading />
    </VAlign>
  </LoadingOverlayContainer>
);
