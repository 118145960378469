import React from "react";
import styled from "styled-components";

import { useIsAdmin, useSetAdmin } from "src/util/admin";

const AdminMount = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  padding: 5px 7px;
  border: 1px solid #000;
  background: rgba(255, 50, 55, 0.5);
`;

const AdminControls: React.FC = () => {
  const isAdmin = useIsAdmin();
  const setAdmin = useSetAdmin();

  if (!isAdmin) return null;

  return (
    <AdminMount
      onClick={() => {
        if (window.confirm("Turn off admin mode?")) setAdmin(false);
      }}
    >
      ADMIN MODE
    </AdminMount>
  );
};

export default AdminControls;
