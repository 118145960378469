import React, { useState, useCallback } from "react";
import styled from "styled-components";

import { PostType } from "src/@common/post/types";
import { mapPostType } from "src/@common/post/mapPost";
import Modal, { ModalProps } from "../../Modal";
import {
  DimensionsWithAuto,
  useWindowProperties,
  M,
  M_,
} from "../../../util/component/dimensions";
import { CalendarIcon, ImagesIcon } from "../../style/Icons";

import CreateMemory from "./CreateMemory";
import CreatePlan from "./CreatePlan";

const ModalBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const SelectPostContainer = styled.div<M>`
  text-align: center;
  font-size: ${M_(30, 25)}px;
  user-select: none;
`;
const SelectPostHeader = styled.div`
  width: 100%;
  padding: 30px 20px;
  box-sizing: border-box;
  color: #555;
`;
const SelectBoxContainer = styled.div`
  text-align: left;
  white-space: normal;
  padding-bottom: 10px;
`;
const SelectBoxSingle = styled.div<M>`
  display: inline-block;
  vertical-align: top;
  margin: 0 0 35px 35px;
  width: calc(${M_("50% - 35px * 3 / 2", "100% - 35px * 2")});
  box-sizing: border-box;
  padding: 20px 20px;
  text-align: center;
  border-radius: 15px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.14);
  background: #eef;
  cursor: pointer;
  &:hover {
    background: #cde;
  }
  &:active {
    background: #abd;
    box-shadow: inset 0px 0px 2px 2px rgba(0, 0, 0, 0.14);
  }
`;
const SelectBoxText = styled.div`
  font-size: 85%;
  margin-bottom: 15px;
`;

const CreatePostModal: React.FunctionComponent<ModalProps & {}> = ({
  ...modalProps
}) => {
  const { mobile } = useWindowProperties();

  const [postType, setPostType] = useState<PostType | undefined>(
    undefined,
    // PostType.Media,
    // PostType.Plan,
  );
  const resetPostType = () => setPostType(undefined);

  const [DNAC, setDNAC] = useState<{ [key: string]: boolean }>({});
  const updateDNAC = useCallback((key: string, on: boolean) => {
    setDNAC(DNAC => ({ ...DNAC, [key]: on }));
  }, []);

  const modalContent = mapPostType<[DimensionsWithAuto, React.ReactNode]>(
    postType,
    {
      [PostType.Media]: () => [
        { width: 960, height: 770 },
        <CreateMemory resetPostType={resetPostType} updateDNAC={updateDNAC} />,
      ],
      [PostType.Plan]: () => [
        { width: 960, height: 770 },
        <CreatePlan resetPostType={resetPostType} updateDNAC={updateDNAC} />,
      ],
      default: () => [
        { width: 500, height: "auto" },
        <SelectPostContainer mobile={mobile}>
          <SelectPostHeader>i want to post</SelectPostHeader>
          <SelectBoxContainer>
            <SelectBoxSingle
              mobile={mobile}
              onClick={() => setPostType(PostType.Media)}
            >
              <SelectBoxText>a memory</SelectBoxText>
              <ImagesIcon size={80} />
            </SelectBoxSingle>
            <SelectBoxSingle
              mobile={mobile}
              onClick={() => setPostType(PostType.Plan)}
            >
              <SelectBoxText>a future plan</SelectBoxText>
              <CalendarIcon size={80} />
            </SelectBoxSingle>
          </SelectBoxContainer>
        </SelectPostContainer>,
      ],
    },
  );

  return (
    <Modal
      {...modalProps}
      modalId="create-new-post"
      desktopDimensions={modalContent[0]}
      doNotAllowClose={Object.keys(DNAC).filter(a => !!a).length > 0}
    >
      <ModalBox>{modalContent[1]}</ModalBox>
    </Modal>
  );
};

export default CreatePostModal;
