import React from "react";
import styled from "styled-components";

import { PersonId } from "src/@common/person/types";

import person1 from "./img/person1.png";
import person2 from "./img/person2.png";

const profileImages = {
  1: person1,
  2: person2,
};

const DEFAULT_AVATAR_SIZE = 50;

const AvatarContainer = styled.div<{ size: number }>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  background: #fff;
`;
const AvatarImage = styled.div<{ url: string }>`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-image: url('${({ url }) => url}');
`;

const PostMediaCard: React.FunctionComponent<{
  personId: PersonId;
  size?: number;
}> = ({ personId, size = DEFAULT_AVATAR_SIZE }) => {
  return (
    <AvatarContainer size={size}>
      <AvatarImage url={profileImages[personId]} />
    </AvatarContainer>
  );
};

export default PostMediaCard;
