import React from "react";

import VAlign from "../components/style/VAlign";
import Loading from "../components/style/Loading";

import AuthLogin from "./AuthLogin";
import { useAPIClient, AUTH_STATUS, APIClientContext } from "../api";

const AuthEntry: React.FunctionComponent<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { authStatus, apiClient, updateApiClient } = useAPIClient();

  if (authStatus === AUTH_STATUS.NOT_AUTHENTICATED) {
    return <AuthLogin refreshCredentials={updateApiClient} />;
  }

  if (authStatus === AUTH_STATUS.AUTHENTICATED && apiClient) {
    return (
      <APIClientContext.Provider value={apiClient}>
        {children}
      </APIClientContext.Provider>
    );
  }

  return (
    <div style={{ height: "100vh", width: "100vw", textAlign: "center" }}>
      <VAlign height100>
        <Loading />
      </VAlign>
    </div>
  );
};

export default AuthEntry;
