import React, { useState, useRef } from "react";
import styled from "styled-components";

import { UploadControls } from "./useUpload";
import SingleUploadedFile, { UploadedFileAdd } from "./SingleUploadedFile";
import MediaEditorModal, { MediaEditorModalProps } from "./MediaEditorModal";

const UploadedFilesContainer = styled.div`
  white-space: normal;
`;

const MediaUpload: React.FunctionComponent<{} & UploadControls> = ({
  uploadFiles,
  uploadedFiles,
  uploadVideoThumbnail,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const onFilesSelect = async () => {
    const { files } = fileInputRef.current!;
    if (!files) return;
    await uploadFiles(Array.from(files));
  };

  const [editModalProps, setEditModalProps] = useState<MediaEditorModalProps>();
  const [showEditor, setShowEditor] = useState<boolean>(false);

  return (
    <>
      <MediaEditorModal
        show={showEditor}
        close={() => setShowEditor(false)}
        mediaEdit={editModalProps}
      />
      <UploadedFilesContainer>
        {uploadedFiles.map(uploadedFile => (
          <SingleUploadedFile
            key={uploadedFile.id}
            clickable={
              uploadedFile.uploadConfirmed && uploadedFile.fileType === "video"
            }
            onClick={() => {
              if (
                uploadedFile.uploadConfirmed &&
                uploadedFile.fileType === "video"
              ) {
                setEditModalProps({
                  uploadedFile,
                  uploadVideoThumbnail,
                });
                setShowEditor(true);
              }
            }}
            uploadedFile={uploadedFile}
          />
        ))}
        <UploadedFileAdd
          onClick={() => fileInputRef.current && fileInputRef.current.click()}
        />
        <input
          style={{ display: "none" }}
          type="file"
          multiple
          ref={fileInputRef}
          onChange={onFilesSelect}
        />
      </UploadedFilesContainer>
    </>
  );
};

export default MediaUpload;
