import React, { useCallback, useState, useEffect } from "react";

export type TouchStatus =
  | { down: false }
  | {
      down: true;
      info: {
        startTime: number;
        startX: number;
        startY: number;
        currentX: number;
        currentY: number;
      };
    };

const useTouch = () => {
  const [touchStatus, setTouchStatus] = useState<TouchStatus>({ down: false });

  useEffect(() => {
    // touch down, begin listening to events
    if (touchStatus.down) {
      const touchmove = (e: TouchEvent) => {
        if (!e.touches.length) return;
        setTouchStatus(touchStatus =>
          touchStatus.down
            ? {
                ...touchStatus,
                info: {
                  ...touchStatus.info,
                  currentX: e.touches[0].pageX,
                  currentY: e.touches[0].pageY,
                },
              }
            : touchStatus,
        );

        if (e.cancelable) e.preventDefault();
        return false;
      };
      window.addEventListener("touchmove", touchmove, { passive: false });

      const touchend = () => {
        setTouchStatus(touchStatus => {
          // dont trigger if its too soon
          return { down: false };
        });
      };
      window.addEventListener("touchend", touchend);

      return () => {
        window.removeEventListener("touchmove", touchmove);
        window.removeEventListener("touchend", touchend);
      };
    }
  }, [touchStatus.down]);

  const startTouch = useCallback((e: React.TouchEvent<HTMLElement>) => {
    if (!e.touches.length) return;
    setTouchStatus({
      down: true,
      info: {
        startTime: new Date().getTime(),
        startX: e.touches[0].pageX,
        startY: e.touches[0].pageY,
        currentX: e.touches[0].pageX,
        currentY: e.touches[0].pageY,
      },
    });
    if (e.cancelable) e.preventDefault();
    return false;
  }, []);

  return {
    touchStatus,
    startTouch,
    abortTouch: () => setTouchStatus({ down: false }),
  };
};

export default useTouch;
