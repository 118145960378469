import React from "react";
import styled from "styled-components";

import PostCard from "../Post/PostCard";
import { LayoutDefinition, GridSize } from "./grid";

const GridLayoutContainer = styled.div`
  position: relative;
  text-align: left;
  white-space: nowrap;
`;
const GridColumn = styled.div<{ gridSizing: GridSize }>`
  display: inline-block;
  vertical-align: top;
  white-space: normal;
  margin-left: ${({ gridSizing: { padding } }) => padding}px;
  &:first-child {
    margin-left: 0;
  }
  width: ${({ gridSizing: { columnWidth } }) => columnWidth}px;
`;
const GridCardContainer = styled.div<{ gridSizing: GridSize }>`
  margin-bottom: ${({ gridSizing: { padding } }) => padding}px;
`;

const GridLayout: React.FunctionComponent<{
  layoutDefinition?: LayoutDefinition;
}> = ({ layoutDefinition }) => {
  if (layoutDefinition === undefined) return null;

  return (
    <GridLayoutContainer>
      {layoutDefinition.columns.map((column, index) => (
        <GridColumn key={index} gridSizing={layoutDefinition.gridSizing}>
          {column.posts.map(({ post }) => (
            <GridCardContainer
              key={post.id}
              gridSizing={layoutDefinition.gridSizing}
            >
              <PostCard
                post={post}
                width={layoutDefinition.gridSizing.columnWidth}
              />
            </GridCardContainer>
          ))}
        </GridColumn>
      ))}
    </GridLayoutContainer>
  );
};

export default GridLayout;
