import React from "react";
import styled from "styled-components";

import left from "./left.svg";
import right from "./right.svg";

const Arrow = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
  top: calc(50% - 40px / 2);
  border-radius: 100%;
  background-color: rgba(255, 255, 255, 1);
  background-size: 50%;
  background-repeat: no-repeat;

  user-select: none;
  transition: opacity 0.1s ease, scale 0.1s ease;
  opacity: 0.45;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &:active {
    transform: scale(1.1);
  }
`;
const ArrowLeft = styled(Arrow)`
  left: 15px;
  background-image: url(${left});
  background-position: 43% 50%;
`;
const ArrowRight = styled(Arrow)`
  right: 15px;
  background-image: url(${right});
  background-position: 57% 50%;
`;

const Arrows: React.FunctionComponent<{
  prev?: (() => any) | false;
  next?: (() => any) | false;
}> = ({ prev, next }) => (
  <>
    {prev && <ArrowLeft onClick={prev} />}
    {next && <ArrowRight onClick={next} />}
  </>
);

export default Arrows;
