import { useState, useEffect } from "react";

import { useUnscopedCallback } from "../../util";

export const useModal = (modalId: string | number | undefined) => {
  const [id] = useState<string>(
    modalId === undefined ? `${Math.random()}`.substring(2) : `${modalId}`,
  );

  return id;
};

let openModal: (
    modal: number,
    canCloseWithEscapeIfOnTop: boolean,
    closeCallback: () => any,
  ) => number,
  closeModal: (modal: number) => void;

(() => {
  const modals: [number, number, boolean, () => any][] = [];
  let modalZIndex = 0;

  openModal = (modalId, canCloseWithEscapeIfOnTop, closeCallback) => {
    const opened = modals.find(([id]) => id === modalId);
    if (opened) return opened[1];
    modals.push([
      modalId,
      modalZIndex++,
      canCloseWithEscapeIfOnTop,
      closeCallback,
    ]);
    document.body.style.overflow = "hidden";
    return modals[modals.length - 1][1];
  };

  closeModal = (modalId: number) => {
    const opened = modals.find(([id]) => id === modalId);
    if (!opened) return;

    const index = modals.indexOf(opened);
    modals.splice(index, 1);
    if (modals.length === 0) document.body.style.overflow = "auto";
  };

  window.addEventListener("keyup", (e: KeyboardEvent) => {
    if (e.key !== "Escape") return;
    if (modals.length === 0) return;

    if (modals[modals.length - 1][2]) {
      modals[modals.length - 1][3]();
    }
  });
})();

export const useOpenCloseModal = (
  visible: boolean,
  canCloseWithEscapeIfOnTop: boolean = false,
  closeCallback: () => any = () => {},
): number => {
  const [modalId] = useState<number>(Math.random());
  const [zIndex, setZIndex] = useState<number>(0);

  useEffect(() => {
    // when we unmount, close the modal by default
    return () => closeModal(modalId);
  }, [modalId]);

  // we need to unscope this since we will be inserting it into a global
  // non react-ified array which will then be called at a random point without scope context
  const unscopedCloseCallback = useUnscopedCallback(closeCallback);

  // open/close modal depending on if its visible
  useEffect(() => {
    if (visible)
      setZIndex(
        openModal(modalId, canCloseWithEscapeIfOnTop, unscopedCloseCallback),
      );
    else closeModal(modalId);
  }, [canCloseWithEscapeIfOnTop, modalId, unscopedCloseCallback, visible]);

  return zIndex;
};
