import { useAsyncQueuedAction, waitForCallback } from "../../../../util";
import { getS3 } from "../../../../util/aws";
import { buildTempMediaPath } from "../../../../config";
import { useGetAPIClient } from "../../../../api";
import {
  UploadedFile,
  useUploadedFileList,
} from "../../../../util/component/uploadedFileList";

export interface UploadControls {
  uploadFiles: (files: File[]) => void;
  uploadedFiles: UploadedFile[];
  uploadVideoThumbnail: (
    uploadedFile: UploadedFile,
    blob: Blob,
  ) => Promise<void>;
  isUploading: boolean;
}
enum MediaConfirmAction {
  UploadComplete = "UploadComplete",
  ChooseThumbnail = "ChooseThumbnail",
}

const useUpload = (
  getTempPostId: () => Promise<string>,
): [UploadControls, () => void] => {
  const apiClient = useGetAPIClient();

  const {
    uploadedFiles,
    createUploadedFile,
    updateUploadedFileWithMediaId,
    updateUploadedFileProgress,
    updateUploadedFileConfirmed,
    updateUploadedFileThumbBlob,
    removeUploadedFile,
    reset: resetUploadedFiles,
  } = useUploadedFileList();

  const addMediaConfirmAction = useAsyncQueuedAction<{
    uploadedFile: UploadedFile;
    action: MediaConfirmAction;
    metadata?: any;
  }>(async mediaList => {
    // set all the medias in the queue to be complete via the API
    const uploadCompleteMedia = mediaList.filter(
      ({ action }) => action === MediaConfirmAction.UploadComplete,
    );
    if (uploadCompleteMedia.length) {
      const medias = uploadCompleteMedia.map(m => ({
        mediaId: m.uploadedFile.id,
        mediaType: m.uploadedFile.fileType,
        raw: m.uploadedFile.uploadedFileName,
      }));
      await apiClient.confirmTempMediaUpload(await getTempPostId(), medias);
      return uploadCompleteMedia;
    }

    // choosing a thumbnail for videos
    const chooseThumbnail = mediaList.find(
      ({ action }) => action === MediaConfirmAction.ChooseThumbnail,
    );
    if (chooseThumbnail) {
      await apiClient.setTempMediaThumbnail(
        await getTempPostId(),
        chooseThumbnail.uploadedFile.id,
        chooseThumbnail.metadata.thumbFileName,
      );
      return chooseThumbnail;
    }
  });

  const uploadSingleFile = async (uploadedFile: UploadedFile) => {
    const s3 = await getS3(apiClient);
    const s3Upload = s3.upload({
      Bucket: "24hoursapart",
      Key: buildTempMediaPath(
        await getTempPostId(),
        uploadedFile.uploadedFileName,
      ),
      ContentType: uploadedFile.file.type,
      Body: uploadedFile.file,
    });
    s3Upload.on("httpUploadProgress", ({ loaded, total }) => {
      updateUploadedFileProgress(uploadedFile.id, loaded / total);
    });
    s3Upload.send(async err => {
      if (err) {
        window.alert("For some reason there was an error uploading");
        removeUploadedFile(uploadedFile.id);
        return;
      }
      // wait for us to confirm that it's been uploaded
      await addMediaConfirmAction({
        uploadedFile,
        action: MediaConfirmAction.UploadComplete,
      });
      // and then set it to be confirmed
      updateUploadedFileConfirmed(uploadedFile.id);
    });
  };

  // this is async but it doesn't actually wait for the file to finish uploading
  // it waits for a successful upload initiating
  const uploadFiles = async (files: File[]) => {
    const tempPostId = await getTempPostId();

    // setup some uploadedFile objects for it
    const uploadedFiles: UploadedFile[] = files.map(file =>
      createUploadedFile(file),
    );

    // create a set of media objects
    const mediaIds = await apiClient.createTempMediaId(
      tempPostId,
      files.length,
    );

    uploadedFiles.forEach((uploadedFile, index) => {
      const extension = uploadedFile.file.name.split(".").pop();
      const uploadedFileName = `${mediaIds[index]}.${extension}`;
      updateUploadedFileWithMediaId(
        uploadedFile,
        mediaIds[index],
        uploadedFileName,
      );

      uploadSingleFile(uploadedFile);
    });
  };

  const uploadVideoThumbnail = async (
    uploadedFile: UploadedFile,
    blob: Blob,
  ) => {
    const thumbFileName = `${uploadedFile.id}_set_thumb.jpg`;
    // update ourselves to render a blob as the preview
    updateUploadedFileThumbBlob(uploadedFile.id, blob, thumbFileName);

    const s3 = await getS3(apiClient);
    const s3Upload = s3.upload({
      Bucket: "24hoursapart",
      Key: buildTempMediaPath(await getTempPostId(), thumbFileName, true),
      ContentType: "image/jpeg",
      Body: blob,
    });
    await waitForCallback<void>(done => {
      s3Upload.send(async err => {
        if (err) window.alert("There was an error setting the thumbnail");
        done();
      });
    });
    // let the server know we've uploaded a thumbnail
    await addMediaConfirmAction({
      uploadedFile,
      action: MediaConfirmAction.ChooseThumbnail,
      metadata: { thumbFileName },
    });
  };

  return [
    {
      uploadFiles,
      uploadedFiles,
      uploadVideoThumbnail,
      isUploading: !uploadedFiles
        .map(uploadedFile => uploadedFile.uploadConfirmed)
        .reduce((a, b) => a && b, true),
    },
    resetUploadedFiles,
  ];
};

export default useUpload;
