import React, { useState, useEffect, useRef } from "react";
import { useAsyncCallback } from "react-async-hook";
import sha256 from "crypto-js/sha256";

import { hashWithSalt } from "src/@common/auth/utils";
import {
  APIAuthStep1Request,
  APIAuthStep1Response,
} from "src/@common/api/24ha-auth";

import { makeRSAPost } from "../api";
import { saveSessionID } from "../util/auth";
import { Label, ErrorMessage, LoadingMessage } from "./common";

const Step1: React.FC<{ onComplete: () => any }> = ({ onComplete }) => {
  const passwordInput = useRef<HTMLInputElement>(null);
  const [password, setPassword] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (passwordInput.current) passwordInput.current.focus();
    }, 100);
  }, []);

  const { loading, error, execute } = useAsyncCallback(async () => {
    const { data: response } = await makeRSAPost<
      APIAuthStep1Request,
      APIAuthStep1Response
    >({
      url: "/auth",
      data: {
        ...hashWithSalt(sha256(password).toString()),
        s: 1,
      },
    });
    saveSessionID(response.sessionID);
    onComplete();
  });

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        execute();
      }}
    >
      <Label>enter password</Label>
      <input
        type="password"
        ref={passwordInput}
        value={password}
        onChange={e => setPassword(e.target.value)}
        autoComplete="off"
        disabled={loading}
      />
      <input type="submit" disabled={loading} />
      {error && <ErrorMessage>{`${error.message}`}</ErrorMessage>}
      {loading && <LoadingMessage>Loading...</LoadingMessage>}
    </form>
  );
};

export default Step1;
