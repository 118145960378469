import React from "react";
import styled from "styled-components";

import write from "./write.svg";
import play from "./play.svg";
import x from "./x.svg";
import plus from "./plus.svg";
import arrowLeft from "./arrow_left.svg";
import calendar from "./calendar.svg";
import images from "./images.svg";

interface IconProps {
  clickable?: boolean;
  onClick?: () => any;

  size?: number;
  transition?: string;

  iconSize?: string;
  iconPosition?: string;
}

const Icon = styled.div<{ url: string } & IconProps>`
  display: inline-block;
  ${({ transition }) =>
    transition && `transition: width ${transition}, height ${transition};`}
  width: ${({ size = 30 }) => size}px;
  height: ${({ size = 30 }) => size}px;
  background-image: url(${({ url }) => url});
  background-size: ${({ iconSize = "cover" }) => iconSize};
  background-repeat: no-repeat;
  background-position: ${({ iconPosition = "center" }) => iconPosition};

  ${({ clickable }) =>
    clickable &&
    `
      cursor: pointer;
      &:hover { opacity: 0.8 }
      &:active { opacity: 0.5 }
    `}

  // border:1px solid #000;
`;

export const WriteIcon = (p: IconProps) => (
  <Icon url={write} iconSize="110%" iconPosition="44% 5%" {...p} />
);
export const PlayIcon = (p: IconProps) => (
  <Icon url={play} iconSize="100%" iconPosition="50% 0%" {...p} />
);
export const XIcon = (p: IconProps) => (
  <Icon url={x} iconSize="100%" iconPosition="50% 0%" {...p} />
);
export const PlusIcon = (p: IconProps) => (
  <Icon url={plus} iconSize="145%" iconPosition="53% 30%" {...p} />
);
export const ArrowLeftIcon = (p: IconProps) => (
  <Icon url={arrowLeft} iconSize="100%" iconPosition="50% 0%" {...p} />
);
export const CalendarIcon = (p: IconProps) => (
  <Icon url={calendar} iconSize="100%" iconPosition="50% 0%" {...p} />
);
export const ImagesIcon = (p: IconProps) => (
  <Icon url={images} iconSize="100%" iconPosition="50% 0%" {...p} />
);
