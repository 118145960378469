import { useState, useCallback } from "react";

import { TempMediaType } from "src/@common/post/temp/types";

export interface UploadedFile {
  id: string;
  file: File;
  fileType: TempMediaType;
  localURL: string;
  uploadProgress: number;
  uploadConfirmed: boolean;
  uploadedFileName: string;
  thumb?: {
    blob: Blob;
    uploadedFileName: string;
  };
}

export const useUploadedFileList = () => {
  // list of uploaded files
  const [uploadedFiles, setUploadedFiles] = useState<UploadedFile[]>([]);

  const reset = useCallback(() => setUploadedFiles([]), []);

  const createUploadedFile = (file: File) => {
    const fileType =
      file.type.indexOf("video") === 0
        ? TempMediaType.Video
        : TempMediaType.Image;
    const uploadedFile: UploadedFile = {
      id: `temp-${Math.random()}`,
      file,
      fileType,
      localURL: URL.createObjectURL(file),
      uploadProgress: 0,
      uploadConfirmed: false,
      uploadedFileName: "",
    };
    setUploadedFiles(uploadedFiles => [...uploadedFiles, uploadedFile]);
    return uploadedFile;
  };
  const updateUploadedFileWithMediaId = (
    uFile: UploadedFile,
    mediaId: string,
    uploadedFileName: string,
  ) => {
    const tempId = uFile.id;
    uFile.id = mediaId;
    uFile.uploadedFileName = uploadedFileName;
    setUploadedFiles(uploadedFiles =>
      uploadedFiles.map(uploadedFile =>
        uploadedFile.id === tempId ? uFile : uploadedFile,
      ),
    );
  };
  const updateUploadedFileProgress = (
    mediaId: string,
    uploadProgress: number,
  ) => {
    setUploadedFiles(uploadedFiles =>
      uploadedFiles.map(uploadedFile =>
        uploadedFile.id === mediaId
          ? { ...uploadedFile, uploadProgress }
          : uploadedFile,
      ),
    );
  };
  const updateUploadedFileConfirmed = (mediaId: string) => {
    setUploadedFiles(uploadedFiles =>
      uploadedFiles.map(uploadedFile =>
        uploadedFile.id === mediaId
          ? { ...uploadedFile, uploadConfirmed: true }
          : uploadedFile,
      ),
    );
  };
  const updateUploadedFileThumbBlob = (
    mediaId: string,
    blob: Blob,
    uploadedFileName: string,
  ) => {
    setUploadedFiles(uploadedFiles =>
      uploadedFiles.map(uploadedFile =>
        uploadedFile.id === mediaId
          ? {
              ...uploadedFile,
              thumb: { blob, uploadedFileName },
            }
          : uploadedFile,
      ),
    );
  };
  const removeUploadedFile = (mediaId: string) => {
    setUploadedFiles(uploadedFiles =>
      uploadedFiles.filter(uploadedFile => uploadedFile.id !== mediaId),
    );
  };

  return {
    uploadedFiles,
    createUploadedFile,
    updateUploadedFileWithMediaId,
    updateUploadedFileProgress,
    updateUploadedFileConfirmed,
    updateUploadedFileThumbBlob,
    removeUploadedFile,
    reset,
  };
};
