type CommonTypeList = (string | number)[];

type HandlerMap<TypeList extends CommonTypeList, K, T extends any[]> = {
  [P in TypeList[number] | "default"]?: (...args: T) => K;
};

export type MapTypeArgs<TypeList extends CommonTypeList, K, T extends any[]> = [
  TypeList[number] | undefined,
  HandlerMap<TypeList, K, T>,
  // TODO: https://github.com/microsoft/TypeScript/pull/39094
  ...unknown[],
];
export const mapType = <
  TypeList extends CommonTypeList,
  K,
  T extends any[] = void[]
>(
  ...arg: MapTypeArgs<TypeList, K, T>
): K => {
  const [type, handlerMap, ...args] = arg;
  if (type && type in handlerMap) {
    const handler = handlerMap[type];
    if (handler !== undefined) return handler(...(args as T));
  }
  if (handlerMap.default) {
    return handlerMap.default(...(args as T));
  }
  throw new Error(`[mapType] Type unhandled "${type}"`);
};

type ObjectTypeMap<TypeList extends CommonTypeList> = {
  [key in TypeList[number]]: unknown;
};
export type HandlerMapWithObject<
  TypeList extends CommonTypeList,
  TypeMap extends ObjectTypeMap<TypeList>,
  K,
  T extends any[]
> = {
  [P in TypeList[number]]?: (object: TypeMap[P], ...args: T) => K;
};
export const mapTypeToObject = <
  TypeList extends CommonTypeList,
  TypeMap extends ObjectTypeMap<TypeList>,
  K,
  T extends any[] = void[]
>(
  generic: { type: TypeList[number] } & object,
  handlerMap: HandlerMapWithObject<TypeList, TypeMap, K, T>,
  ...args: T
): K => {
  if (generic && generic.type in handlerMap) {
    const handler = handlerMap[generic.type];
    if (handler !== undefined)
      return handler(generic as TypeMap[TypeList[number]], ...args);
  }
  throw new Error(`[mapTypeToObject] Type unhandled "${generic.type}"`);
};
