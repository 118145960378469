import React, { useEffect } from "react";
import styled from "styled-components";

import { useWindowProperties } from "../../../../util/component/dimensions";
import Link from "../../../style/Link";

import useCreatePlan from "./useCreatePlan";

import {
  ModalLoadingOverlay,
  CreateContentContainerWithBar,
  FirstLine,
  MajorLine,
  LabelText,
  EditMajorLine,
  TextBox,
  BottomBar,
} from "./../common";

const SingleTextBlock = styled.div`
  margin-top: 30px;
`;
const TextBoxSpace = styled(TextBox)`
  margin-top: 15px;
`;

const CreateMemory: React.FunctionComponent<{
  resetPostType: () => any;
  updateDNAC: (key: string, DNAC: boolean) => any;
}> = ({ resetPostType, updateDNAC }) => {
  const { mobile } = useWindowProperties();

  const {
    text: { text, setText },
    thoughtText: { thoughtText, setThoughtText },
    create,
    canCreate,
    loading: createPostLoading,
  } = useCreatePlan();

  useEffect(() => {
    if (createPostLoading) {
      updateDNAC("create-memory", true);
      return () => updateDNAC("create-memory", false);
    }
  }, [createPostLoading, updateDNAC]);

  return (
    <>
      {createPostLoading && <ModalLoadingOverlay />}
      <CreateContentContainerWithBar mobile={mobile}>
        <FirstLine>
          <LabelText>i'm making plans for our future</LabelText>
        </FirstLine>
        <EditMajorLine>
          <Link onClick={() => resetPostType()}>
            (i want a different kind of post)
          </Link>
        </EditMajorLine>
        <SingleTextBlock>
          <MajorLine>
            <LabelText>one day,</LabelText>
          </MajorLine>
          <TextBoxSpace
            mobile={mobile}
            placeholder="what are your plans?"
            value={text}
            onChange={e => setText(e.target.value)}
          />
        </SingleTextBlock>
        <SingleTextBlock>
          <MajorLine>
            <LabelText>i/we thought of this while</LabelText>
          </MajorLine>
          <TextBoxSpace
            mobile={mobile}
            placeholder="when did you think of this? (this is optional!)"
            value={thoughtText}
            onChange={e => setThoughtText(e.target.value)}
          />
        </SingleTextBlock>
      </CreateContentContainerWithBar>
      <BottomBar
        canCreate={canCreate}
        cannotCreateMessage={"add some plans"}
        onCreate={create}
      />
    </>
  );
};

export default CreateMemory;
