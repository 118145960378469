import { Post, PostType } from "../../post/types";
import { TempMediaType, TempPostCreateData } from "../../post/temp/types";

// get post(s)
export type APIPostsGetPostRequest = { postId: string };
export type APIPostsGetPostResponse = { post: Post };
export type APIPostsGetPostsRequest = {
  start?: string | number;
  count?: string | number;
};
export interface APIPostsGetPostsResponse {
  posts: Post[];
  start: number;
  end: number;
}

// delete a post
export type APIPostsDeleteRequest = {
  postId: string;
};
export type APIPostsDeleteResponse = { deleted: true; postId: string };

export enum APICreatePostAction {
  SetupTempPost = "setup-temp-post",
  MediaAddMedia = "temp-post-add-media",
  MediaConfirmMedia = "temp-post-confirm-media-uploaded",
  MediaSelectThumb = "temp-post-media-select-thumb",
  Create = "create-post",
  CreateFinalize = "create-post-finalize",
}

// create a post
export type APIPostsCreatePostSetupRequest = {
  action: APICreatePostAction.SetupTempPost;
  type: PostType;
};
export type APIPostsCreatePostSetupResponse = { tempPostId: string };

export type APIPostsCreatePostMediaAddMediaRequest = {
  action: APICreatePostAction.MediaAddMedia;
  tempPostId: string;
  mediaCount: number;
};
export type APIPostsCreatePostMediaAddMediaResponse = { mediaIds: string[] };

export type APIPostsCreatePostMediaConfirmMediaSingle = {
  mediaId: string;
  mediaType: TempMediaType;
  raw: string;
};
export type APIPostsCreatePostMediaConfirmMediaRequest = {
  action: APICreatePostAction.MediaConfirmMedia;
  tempPostId: string;
  medias: APIPostsCreatePostMediaConfirmMediaSingle[];
};
export type APIPostsCreatePostMediaConfirmMediaResponse = { ok: true };

export type APIPostsCreatePostMediaSelectThumbRequest = {
  action: APICreatePostAction.MediaSelectThumb;
  tempPostId: string;
  mediaId: string;
  thumbFileName: string;
};
export type APIPostsCreatePostMediaSelectThumbResponse = { ok: true };

export type APIPostsCreatePostCreateRequest = {
  action: APICreatePostAction.Create;
  tempPostId: string;
  createData: TempPostCreateData;
};
export type APIPostsCreatePostCreateResponse = { ok: true };

export type APIPostsCreatePostCreateFinalizeRequest = {
  action: APICreatePostAction.CreateFinalize;
  tempPostId: string;
};
export type APIPostsCreatePostCreateFinalizeResponse =
  | { posted: false }
  | {
      posted: true;
      postId: string;
    };
