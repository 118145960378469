import styled from "styled-components";

export const HOVER_COLOR = "#339";
export const ACTIVE_COLOR = "#77d";

export default styled.div<{ disabled?: boolean }>`
  display: inline-block;
  cursor: pointer;
  padding: 10px 15px;
  transition: background 0.1s ease;
  user-select: none;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.3);
  background: #24a;
  color: #fff;
  border-radius: 5px;
  &:hover {
    background: #46b;
  }
  &:active {
    background: #69e;
  }
  ${({ disabled }) =>
    disabled &&
    `
      background: #ddd !important;
      color: #555;
      cursor: not-allowed;
    `}
`;
