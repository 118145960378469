import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
// import debounce from "lodash/debounce";

import { useUniversalVideoManager } from "../../../util/component/video";
import { Media } from "src/@common/post/types";
import { PlayIcon } from "../../style/Icons";
import { MediaImageContainer } from "./MediaImageViewer";

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;
const VideoPlayContainer = styled.div`
  cursor: pointer;
  width: 100%;
  height: 100%;
`;
const VideoVideo = styled.video`
  width: 100%;
  height: 100%;
`;
const PlayIconContainer = styled.div`
  position: absolute;
  left: calc(50% - 25px - 7px);
  top: calc(50% - 25px - 7px);
  padding: 14px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 7px;
`;

const MediaVideoViewer: React.FunctionComponent<{
  media: Media;
  preview: boolean;
}> = ({ media, preview }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [videoActive, setVideoActive] = useState<boolean>(false);

  const pauseAllOtherVideos = useUniversalVideoManager(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      setIsPlaying(false);
    }
  });

  // when the video activates, we are playing
  useEffect(() => {
    if (videoActive) setIsPlaying(true);
  }, [videoActive]);

  // when we're playing, pause everything else
  useEffect(() => {
    if (isPlaying) pauseAllOtherVideos();
  }, [pauseAllOtherVideos, isPlaying]);

  const togglePlayPause = () => {
    setIsPlaying(videoRef.current!.paused);
    if (videoRef.current!.paused) videoRef.current!.play();
    else videoRef.current!.pause();
  };

  return (
    <VideoContainer>
      {videoActive ? (
        <VideoPlayContainer onClick={togglePlayPause}>
          <VideoVideo controls={false} autoPlay ref={videoRef} loop>
            <source
              type="video/mp4"
              src={preview ? media.url.preview : media.url.full}
            />
          </VideoVideo>
          {!isPlaying && (
            <PlayIconContainer>
              <PlayIcon size={50} transition="0.2s ease" clickable />
            </PlayIconContainer>
          )}
        </VideoPlayContainer>
      ) : (
        <VideoPlayContainer onClick={() => setVideoActive(true)}>
          <MediaImageContainer url={media.url.thumb!} />
          <PlayIconContainer>
            <PlayIcon size={50} transition="0.2s ease" clickable />
          </PlayIconContainer>
        </VideoPlayContainer>
      )}
    </VideoContainer>
  );
};

export default MediaVideoViewer;
