import { useState, useEffect } from "react";

export const useScrollToBottom = (
  trigger: () => any,
  padding: number = 600,
) => {
  const [scrolled, setScrolled] = useState<number>(0);

  // attach event handlers to the window to listen
  useEffect(() => {
    const triggerScroll = () => setScrolled(Math.random());
    window.addEventListener("scroll", triggerScroll);
    return () => window.removeEventListener("scroll", triggerScroll);
  }, []);

  // check scroll position
  useEffect(() => {
    if (
      window.innerHeight + window.scrollY >=
      document.body.offsetHeight - padding
    ) {
      trigger();
    }
  }, [scrolled, padding, trigger]);
};
