import { useState, useEffect, useCallback } from "react";

let universalVideoPause: (exceptionVideoId?: number) => void,
  useUniversalVideoManager: (pauseHandler: () => any) => () => void;

(() => {
  const triggers: ((exceptionVideoId: number) => any)[] = [];
  let globalVideoId = 0;

  universalVideoPause = (exceptionVideoId = -1) => {
    for (let trigger of triggers) trigger(exceptionVideoId);
  };

  useUniversalVideoManager = pauseHandler => {
    // our video id
    const [videoId] = useState<number>(globalVideoId++);
    const [pause, setPause] = useState<number>(0);

    // pause the video
    useEffect(() => {
      pauseHandler();
    }, [pause]); // eslint-disable-line react-hooks/exhaustive-deps

    // CDM, add a trigger to pause
    useEffect(() => {
      const handler = (exceptionVideoId: number) => {
        // this way we don't pause ourselves
        if (exceptionVideoId !== videoId) setPause(Math.random());
      };
      triggers.push(handler);
      return () => {
        triggers.splice(triggers.indexOf(handler), 1);
      };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    return useCallback(() => universalVideoPause(videoId), [videoId]);
  };
})();

export { universalVideoPause, useUniversalVideoManager };
