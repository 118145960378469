import React from "react";
import styled from "styled-components";

import { PostTypeMedia, Occasion } from "src/@common/post/types";
import {
  useWindowProperties,
  M,
  M_,
} from "../../../../util/component/dimensions";

const TopSection = styled.div``;
const Bar = styled.div<M>`
  user-select: none;
  font-size: ${M_(16, 14)}px;
  padding: ${M_("11px 17px 10px", "7px 11px 7px")};
`;
const FirstTimeBar = styled(Bar)`
  background: #def;
`;
const FirstTimePreText = styled.div`
  display: inline-block;
  color: #88a;
  margin-right: 6px;
`;
const OccasionBar = styled(Bar)`
  background: #fed;
`;

const OccasionMappings: Record<Occasion, string> = {
  christmas: "merry christmas 🎊🥳",
  newyears: "happy new year 🎄🦌⛄🎅",
  birthday1: "roger's birthday 🎂🥳🎁",
  birthday2: "hayoung's birthday 🎂🥳🎁",
  anniversary: "happy anniversary 🧑‍🤝‍🧑",
};

const PostMediaTop: React.FunctionComponent<{
  post: PostTypeMedia;
}> = ({ post }) => {
  const { mobile } = useWindowProperties();

  if (!post.occasion && !post.firstTime) return null;

  const bars: React.ReactNode[] = [];

  if (post.occasion) {
    bars.push(
      <OccasionBar mobile={mobile} key="occasion">
        {OccasionMappings[post.occasion]}
      </OccasionBar>,
    );
  }

  if (post.firstTime) {
    bars.push(
      <FirstTimeBar mobile={mobile} key="firsttime">
        <FirstTimePreText>first time</FirstTimePreText>
        {post.firstTimeText}
      </FirstTimeBar>,
    );
  }

  return <TopSection>{bars}</TopSection>;
};

export default PostMediaTop;
