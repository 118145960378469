import React, { useState, useRef, useEffect } from "react";
import { useAsyncCallback } from "react-async-hook";
import sha256 from "crypto-js/sha256";

import { hashWithSalt } from "src/@common/auth/utils";
import {
  APIAuthStep2Request,
  APIAuthStep2Response,
} from "src/@common/api/24ha-auth";

import { makeRSAPost } from "../api";
import { saveSessionToken } from "../util/auth";
import { Label, ErrorMessage, LoadingMessage } from "./common";

const Step2: React.FC<{ onComplete: () => any; sessionID: string }> = ({
  onComplete,
  sessionID,
}) => {
  const textInput = useRef<HTMLInputElement>(null);
  const [text, setText] = useState("");

  useEffect(() => {
    setTimeout(() => {
      if (textInput.current) textInput.current.focus();
    }, 100);
  }, []);

  const { loading, error, execute } = useAsyncCallback(async () => {
    const { data: response } = await makeRSAPost<
      APIAuthStep2Request,
      APIAuthStep2Response
    >({
      url: "/auth",
      data: {
        ...hashWithSalt(sha256(text).toString()),
        s: 2,
        id: sessionID,
      },
    });
    saveSessionToken(response.sessionToken);
    onComplete();
  });

  return (
    <form
      onSubmit={async e => {
        e.preventDefault();
        execute();
      }}
    >
      <Label>enter text code</Label>
      <input
        type="text"
        ref={textInput}
        value={text}
        onChange={e => setText(e.target.value)}
        autoComplete="off"
        disabled={loading}
      />
      <input type="submit" disabled={loading} />
      {error && <ErrorMessage>{`${error.message}`}</ErrorMessage>}
      {loading && <LoadingMessage>Loading...</LoadingMessage>}
    </form>
  );
};

export default Step2;
