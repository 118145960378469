import { Post, PostTypeMap, PostTypeList } from "./types";
import {
  mapType,
  MapTypeArgs,
  mapTypeToObject,
  HandlerMapWithObject,
} from "../util";

export const mapPostType = <K, T extends any[] = void[]>(
  ...arg: MapTypeArgs<PostTypeList, K, T>
) => mapType<PostTypeList, K, T>(...arg);

export const mapPostByType = <K, T extends any[] = void[]>(
  post: Post,
  handlerMap: HandlerMapWithObject<PostTypeList, PostTypeMap, K, T>,
  ...args: T
) =>
  mapTypeToObject<PostTypeList, PostTypeMap, K, T>(post, handlerMap, ...args);

export const mapPostsByType = <K, T extends any[] = void[]>(
  posts: Post[],
  handlerMap: HandlerMapWithObject<PostTypeList, PostTypeMap, K, T>,
  ...args: any
): K[] => posts.map(post => mapPostByType(post, handlerMap, ...args));
