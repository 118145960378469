import { useState, useEffect, useCallback } from "react";

import { wait } from "../../../../util";
import { useGetAPIClient } from "../../../../api";
import { UploadedFile } from "../../../../util/component/uploadedFileList";
import { Occasion } from "src/@common/post/types";

export enum CreatePostStatus {
  NotPosted,
  Posting,
  Waiting,
  CheckWaiting,
  Posted,
}

const useCreatePost = (
  onCreate: (postId: string) => any,
  getTempPostId: () => Promise<string>,
  {
    date: [today, theDate],
    uploadedFiles,
    text,
    occasion,
    firstTime,
  }: {
    date: [boolean, { year: number; month: number; date: number }, any, any];
    uploadedFiles: UploadedFile[];
    text: string;
    occasion: [boolean, Occasion | undefined];
    firstTime: [boolean, string | undefined];
  },
  { isUploading }: { isUploading: boolean },
): [
  {
    canCreate: boolean;
    cannotCreateMessage: string;
    loading: boolean;
    createPost: () => void;
  },
  () => void,
] => {
  const apiClient = useGetAPIClient();

  const [status, setStatus] = useState<CreatePostStatus>(
    CreatePostStatus.NotPosted,
  );

  const reset = useCallback(() => setStatus(CreatePostStatus.NotPosted), []);

  // when we hit waiting status, wait a bit and then check
  useEffect(() => {
    if (status === CreatePostStatus.Waiting) {
      // wait a bit
      (async () => {
        // TODO: these need to be cancellable, so a useWait would be nice, or something like that
        await wait(1000);
        setStatus(CreatePostStatus.CheckWaiting);
      })();
    } else if (status === CreatePostStatus.CheckWaiting) {
      // go check
      (async () => {
        const postId = await apiClient.finalizeCreatePost(
          await getTempPostId(),
        );
        if (!postId) {
          setStatus(CreatePostStatus.Waiting);
        } else {
          setStatus(CreatePostStatus.Posted);
          onCreate(postId);
        }
      })();
    }
  }, [status]); // eslint-disable-line react-hooks/exhaustive-deps

  const loading =
    status !== CreatePostStatus.NotPosted && status !== CreatePostStatus.Posted;

  let canCreate = true,
    cannotCreateMessage = "";
  if (occasion[0] && !occasion[1]) {
    canCreate = false;
    cannotCreateMessage = "choose an occasion";
  } else if (firstTime[0] && !firstTime[1]) {
    canCreate = false;
    cannotCreateMessage = "what was this the first time for?";
  } else if (isUploading) {
    canCreate = false;
    cannotCreateMessage = "hold on, uploading some stuff";
  } else if (!uploadedFiles.length) {
    canCreate = false;
    cannotCreateMessage = "add some pictures/videos";
  } else if (loading) {
    cannotCreateMessage = "hold on...";
  }

  const createPostFunction = async () => {
    if (!canCreate) return;

    if (status !== CreatePostStatus.NotPosted) return;
    setStatus(CreatePostStatus.Posting);
    await apiClient.createPostMedia(await getTempPostId(), {
      date: { today, ...theDate },
      mediaConfig: uploadedFiles.map(uploadedFile => ({
        mediaId: uploadedFile.id,
      })),
      text,
      occasion,
      firstTime,
    });
    setStatus(CreatePostStatus.CheckWaiting);
  };

  return [
    {
      canCreate,
      cannotCreateMessage,
      loading,
      createPost: createPostFunction,
    },
    reset,
  ];
};

export default useCreatePost;
