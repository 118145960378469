import { useCallback, useState, useEffect } from "react";

import { TouchStatus } from "./useTouch";

export type Swipe = { deltaX: number };

const useSwipeFromTouch = (touchStatus: TouchStatus, abortTouch: () => any) => {
  const [swipe, setSwipe] = useState<Swipe>();

  const updateSwipe = useCallback(
    (deltaX: number) => {
      if (swipe && swipe.deltaX === deltaX) return;
      setSwipe({ deltaX });
    },
    [swipe],
  );

  // processing the touch events into swipe
  useEffect(() => {
    if (touchStatus.down) {
      const { startX, startY, currentX, currentY } = touchStatus.info;
      const deltaX = currentX - startX,
        deltaY = currentY - startY;

      // have not yet registered a swipe
      if (!swipe) {
        const absDX = Math.abs(deltaX),
          absDY = Math.abs(deltaY);

        // check to see if we should register a swipe
        // conditions: timeElapsed < 0.5s
        // and one of:
        // - delta x > delta y, x > ~20px
        // - delta x > delta y * 3, x > 5px
        if (
          ((absDX > absDY && absDX > 20) || (absDX > absDY * 3 && absDX > 5)) &&
          new Date().getTime() - touchStatus.info.startTime <= 500
        ) {
          updateSwipe(deltaX);
        }

        // if delta y > delta x * 3 && y > 10px, then assume it's a scroll
        // so abort any potential swipe
        if (
          (absDX > absDY && absDX > 20) ||
          (absDY > absDX * 3 && absDY > 10)
        ) {
          abortTouch();
        }
      } else {
        updateSwipe(deltaX);
      }
    }
  }, [touchStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  return { swipe, clearSwipe: () => setSwipe(undefined) };
};

export default useSwipeFromTouch;
